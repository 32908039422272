import { Link } from 'react-router-dom'
import { useState, useEffect, React , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function DSleftmenu(props) {
    //const [value,setvalue]=useState(0);
    const [mydata, setmydata] = useReducer(myreducer, initialstate);
    //const imagefromdb = "https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagefromdb = "./uploads/";
    const imagedefault = "/assets/images/users/avatar-1.jpg";
    let z;
    async function loaddata() {
        //console.log("tempID",localStorage.getItem("tempID"));
      
        fetchbygetmethod(setmydata, 'profileinformation', { tempid:localStorage.getItem("tempID") });


    }

    useEffect(() => {

        loaddata();


    }, [])



    return (<div className="left side-menu">
        <div className="sidebar-inner slimscrollleft">
            <div className="user-details">
                <div className="text-center">
                    <img src={mydata.mypost.length > 0 && mydata.mypost[0].ApplicantPhoto ? imagefromdb + mydata.mypost[0].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                        className="img-circle" />
                </div>
                <div className="user-info">
                    <div className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown"
                            aria-expanded="false">{mydata.mypost.length > 0 && mydata.mypost[0].ApplicantName}</a>
                        <ul className="dropdown-menu">
                            <li><a href="javascript:void(0)"> Profile</a></li>
                            <li><a href="javascript:void(0)"> Settings</a></li>
                            <li><a href="javascript:void(0)"> Lock screen</a></li>
                            <li className="divider"></li>
                            <li><a href="javascript:void(0)"> Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="sidebar-menu">

                <ul >
                    <li><Link to='DSDashboard'>Dashboard</Link></li>
                    <li className="has_sub" onClick={() => {
                        var a = document.getElementById('updateprofile');
                        a.style.display = a.style.display == 'none' ? 'block' : 'none';
                        console.log("working");
                    }}><a href="javascript:void(0);"
                        className="waves-effect">
                            <i className="mdi mdi-album"></i>
                            <span>User Profile </span>
                            <span className="pull-right">
                                <i className="mdi mdi-plus"></i></span></a>

                        <ul id='updateprofile' className="list-unstyled">
                            {/* <li><Link to='userprofile' >User Profile</Link></li> */}
                            <li><Link to='personaldetails' >Personal Profile</Link></li>
                            <li><Link to='communicationdetail' >Communication Details</Link></li>
                            <li><Link to='bankdetails' >Bank Details</Link></li>
                            <li><Link to='joiningdetail' >Joining Details</Link></li>
                            <li><Link to="uploadpicture">Upload Picture</Link></li>
                            <li><Link to="changepassword">Change Password</Link></li>
                            <li><Link to="idcard">Id Card</Link></li>

                        </ul>

                    </li>
                    <li className="has_sub" onClick={() => {
                        var a = document.getElementById('reports');
                        a.style.display = a.style.display == 'none' ? 'block' : 'none';
                    }}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i>
                            <span>Reports </span>
                            <span className="pull-right"><i className="mdi mdi-plus">
                            </i></span></a>
                        <ul id='reports' className="list-unstyled">
                            <li><Link to="Yourgroup">Your Group</Link></li>
                            <li><Link to='awarenessincome'>Awareness Income Details</Link></li>
                            <li><Link to='yourbusiness'>Your Business</Link></li>
                            <li><Link to='businesstransaction'>Consolited Business</Link></li>
                            <li><Link to='monthlystate'>Monthly Statement</Link></li>
                            <li><Link to='financialyear'>Financial Year Purchasing </Link></li>
                            <li><Link to='businessclub'>Business Building Club </Link></li>
                            <li><Link to='businessclub_2024'>Business Building Club_2024 </Link></li>
                            <li><Link to='iwd'>Income Awareness Detail </Link></li>
                            <li><Link to='matchingpointds'>Matching Point Income Awareness </Link></li>
                            <li><Link to='directi'>Direct Income   </Link></li>
                            <li><Link to='directia'>Direct Income Awareness    </Link></li>
                            {/* <li><Link to='repurchaseincome'>Repurchase Income Report</Link></li> */}

                        </ul></li>

                    {/* <li><Link to='Awarenesstransfer' class="waves-effect"><i
                        class="mdi mdi-home"></i><span> Awareness Tranfer  <span
                            class="badge badge-primary pull-right">1</span></span></Link></li> */}
                    <li><Link to='producttraning' class="waves-effect"><i
                        class="mdi mdi-home"></i><span> Product Training  <span
                            class="badge badge-primary pull-right">1</span></span></Link></li>
                    <li><Link to='DsproductList'  class="waves-effect"><i
                        class="mdi mdi-home"></i><span> Product List  <span
                            class="badge badge-primary pull-right">1</span></span></Link></li>

                </ul>
            </div>
            <div className="clearfix"></div>
        </div>
        {/* <Gotohome mydata={mydata}/> */}
    </div>)

}

export default DSleftmenu;