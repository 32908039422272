import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
function Adminsalevoucher()
{

var [mydata,setmydata]=useState([]);
var [invoiceno,setinvoiceno]=useState('JMVD999999-TI336') ;

function createinvoiceno()
{
	var ob=mydata[0];
	alert("you are in "+ob);
	if(mydata.length>0)
	{
		var str = mydata[0].Invoiceno;//"JMVD999999-TI336"
		alert("inv"+ mydata[0].Invoiceno);
		var index = str.indexof('TI');//11
		if (index !== -1) { // Check if "TI" is found in the string
			const prefix = str.substring(0, index + 2); // Include "TI" in the prefix
			let suffix = str.substring(index + 2); // Include characters after "TI" in the suffix
			// Increment the suffix by 1
			suffix = parseInt(suffix) + 1;
			setinvoiceno(prefix+suffix);
			console.log("Prefix:", prefix); // Output: "JMVD999999-TI"
			console.log("Suffix:", suffix); // Output: 337
		} else {
			console.log("Substring 'TI' not found in the string.");
		}
	}
}

	async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'AdminSaleVoucher');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
	if(mydata.length>0)
	{
		 createinvoiceno();
	}
  }
 
  useEffect(()=>{loaddata();
              
  },[]);

 


  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Sale Voucher</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Sale Voucher</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
                                                           
																<Link to={`/AdminAddEditSaleInvoice`} type="submit"
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
																	Add New Sale Invoice</Link>

															</div>
														</div> 
                                            
                                                       
                                                        	
                                                          
													
												</div>

                                             <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Invoice Type</th>
													<th>Invoice Number</th>
													<th>Invoice Date</th>
													<th>Status</th>
													
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													<td>{index+1}</td>
                                                     <td>{row.InvoiceType}</td>
													<td>{row.InvoiceNo}</td>
                                                    <td>{row.Dos}</td>
													<td>{row.status=='Y'?'UnBlock':'Block'}</td>
													
                                            
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							
							
						
							
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Adminsalevoucher;