import { Link } from 'react-router-dom'
import { useState, useEffect, React , useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
function Branchleftmenu(props) {
    //const [value,setvalue]=useState(0);
    const [mydata, setdata] =useReducer(myreducer, initialstate);
   // const imagefromdb = "https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagefromdb = "./uploads/";
    const imagedefault = "/assets/images/users/avatar-1.jpg";
    let z;
    async function loaddata() {
        // var x = await fetch(process.env.REACT_APP_URL+"profileinformation?tempid=" +localStorage.getItem("tempID"));
        // console.log("id: " +localStorage.getItem("tempID"));
        // console.log("URL: " + x);
        // var y = await x.json();
        // z = await y.recordset;
        // console.log('dataprofileleft', z);
        // setdata(z);
        // console.log('dataprofileinfoleft', mydata);

        fetchbygetmethod(setdata, 'profileinformation', { tempid: localStorage.getItem("tempID") });
    }

    useEffect(() => {

        loaddata();


    }, [])



    return (<div className="left side-menu">
        <div className="sidebar-inner slimscrollleft">
            <div className="user-details">
                <div className="text-center">
                    <img src={mydata.mypost!=null &&  mydata.mypost.length > 0 && mydata.mypost[0].ApplicantPhoto ? imagefromdb + mydata.mypost[0].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt=""
                        className="img-circle" />
                </div>
                <div className="user-info">
                    <div className="dropdown">
                        <a href="#" className="dropdown-toggle" data-toggle="dropdown"
                            aria-expanded="false">{mydata.mypost!=null && mydata.mypost.length > 0 && mydata.mypost[0].ApplicantName}</a>
                        <ul className="dropdown-menu">
                            <li><a href="javascript:void(0)"> Profile</a></li>
                            <li><a href="javascript:void(0)"> Settings</a></li>
                            <li><a href="javascript:void(0)"> Lock screen</a></li>
                            <li className="divider"></li>
                            <li><a href="javascript:void(0)"> Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="sidebar-menu">

                <ul >
                    <li className="has_sub" onClick={() => {
                        var a = document.getElementById('registrationData');
                        a.style.display = a.style.display == 'none' ? 'block' : 'none';
                        console.log("working");
                    }}><a href="javascript:void(0);"
                        className="waves-effect">
                            <i className="mdi mdi-album"></i>
                            <span>Registration Data </span>
                            <span className="pull-right">
                                <i className="mdi mdi-plus"></i></span></a>

                                {/* <li><Link to='branchdashboard' class="waves-effect"><i
                        class="mdi mdi-home"></i><span>Dashboard<span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li> */}

                        <ul id='registrationData' className="list-unstyled">
                            <li><Link to='Brpersonaldetails' >Personal Detail</Link></li>
                            <li><Link to="communicationdetail">Communication Detail</Link></li>
                            <li><Link to="bankdetail">Bank Detail</Link></li>
                            <li><Link to="paneldetail">Panel Detail</Link></li>

                        </ul>

                    </li>
                    <li className="has_sub" onClick={() => {
                        var a = document.getElementById('inventoryTransaction');
                        a.style.display = a.style.display == 'none' ? 'block' : 'none';
                    }}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i>
                            <span>Inventory Transaction </span>
                            <span className="pull-right"><i className="mdi mdi-plus">
                            </i></span></a>
                        <ul id='inventoryTransaction' className="list-unstyled">
                            <li><Link to='purchaseapprove'>Purchase Approve</Link></li>
                            <li><Link to='salevoucher'>Sale Voucher</Link></li>
                        </ul>
                    </li>
                    <li className="has_sub" onClick={() => {
                        var a = document.getElementById('inventoryReports');
                        a.style.display = a.style.display == 'none' ? 'block' : 'none';
                    }}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i>
                            <span>Inventory Reports </span>
                            <span className="pull-right"><i className="mdi mdi-plus">
                            </i></span></a>
                        <ul id='inventoryReports' className="list-unstyled">
                            <li><Link to='stock'>Stock</Link></li>
                            <li><Link to='sale'>Sale</Link></li>
                            {/* <li><Link to='consolidatebusiness'>Consolidate Business</Link></li> */}
                        </ul>
                    </li>

                    {/* <li><Link to='yourkyc' class="waves-effect"><i
                        class="mdi mdi-home"></i><span> Your KYC<span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li> */}
                    {/* <li><Link to='paymentclearance' class="waves-effect"><i
                        class="mdi mdi-home"></i><span> Payment Clearance  <span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li> */}
                    {/* <li><Link to='productorderform' class="waves-effect"><i
                        class="mdi mdi-home"></i><span>Product Order Form<span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li> */}
                    <li><Link to='companybankdetails' class="waves-effect"><i
                        class="mdi mdi-home"></i><span>Company Bank Details<span
                            class="badge badge-primary pull-right">1</span></span></Link>
                    </li>
                    <li><Link  to='ProductList' class="waves-effect"><i
											class="mdi mdi-home"></i><span> Product List <span
												class="badge badge-primary pull-right">1</span></span></Link></li>
                                                 					
                    
                </ul>
            </div>
            <div className="clearfix"></div>
        </div>
        <Gotohome mydata={mydata} />
    </div>)

}

export default Branchleftmenu;