import { useState,useEffect ,React} from "react";
import axios from 'axios';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditPurchaseVoucher()
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    let z;
    async   function loaddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"pd?id=2082");
        var y=await x.json();
          z=await y.recordset;
          console.log('data10',z );
          setdata(z);
         console.log('data2',mydata );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    
    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Purchase Voucher</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add /Edit Purchase Voucher</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:800}}>
                                                        Go Back</button>       
                                                        
                                                    </div>
                                                </div> 


                                 <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Enter Purchase Order" aria-label="Search"  />
															</div>
															</div>
														</div>
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Invoice Number</label>
                                                    <p  class='form-control'>INV860</p>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Invoice Type</label>
                                                   <select class='form-control'>
                                                   <option>Purchase</option>
                                                   <option>Purchase Return</option>
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Vendor id</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Unit</label>
                                                    <select class='form-control'>
                                                        <option>---Select Unit---</option>
                                                    </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Item Id</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Received Items</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Batch No.</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Expiry Date</label>
                                                   <input type="date" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Reverse Charge</label>
                                                   <input type="text" value='0' class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Date Of Supply</label>
                                                    <input type="date" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Insurance Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                  
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Frieght Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Other Charge</label>
                                                    <input type="text" value={0} class='form-control'/>
                                                       
                                                    
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>GR NO</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Loading & Packing Charge</label>
                                                   <input type="text" value={0} class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Transport Name</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>NAG</label>
                                                   <input type="date" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Vehicle No:</label>
                                                   <input type="text" value='0' class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                          <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Add</button>   
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:772}}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditPurchaseVoucher;