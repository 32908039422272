import React, { useState, useEffect } from 'react';

function AdminChangePassword() {
    const [data, setData] = useState([]);

    async function loaddata(event) {
        event.preventDefault(); // Prevent default form submission behavior

        const old = document.getElementById('old').value;
        const nyu = document.getElementById('nyu').value;
        const tempid = document.getElementById('tempid').value;
        const nyuvalid = document.getElementById('nyuvalid').value;

        if (nyuvalid !== nyu) {
            alert('Confirm Password Does Not Match.');
            return;
        }

        const jsonobj = { old: old, nyu: nyu, tempid: tempid };

        try {
            const response = await fetch(process.env.REACT_APP_URL+"updatepasswordadmin?tempid="+tempid+"&old="+old+"&nyu="+nyu, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(jsonobj)
            });

            const result = await response.json();
            console.log("change password", result);

            alert('Password Changed Successfully.');
            setTimeout(() => {
                window.location.reload();
            }, 1000); // Delay the reload by 1 second to ensure the alert is visible

        } catch (error) {
            console.error("Error changing password:", error);
            alert('An error occurred while changing the password.');
        }
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="">
                    <div className="page-header-title">
                        <h4 className="page-title">Change Password</h4>
                    </div>
                </div>

                <div className="page-content-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="panel panel-primary">
                                    <div className="panel-body">
                                        <h4 className="m-t-0 m-b-30">Change Password</h4>
                                        <hr width="100%" />

                                        <div className="row">
                                            <div className="col-sm-6 col-xs-12">
                                                <div className="m-t-20">
                                                    <form className="" onSubmit={loaddata}>
                                                        <div className="form-group">
                                                            <label>Temp ID</label>
                                                            <input type='text' className="form-control" placeholder="Type in Tempid" id='tempid' required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Old Password</label>
                                                            <input type='text' className="form-control" placeholder="Type in Old Password" id='old' required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>New Password</label>
                                                            <input type='text' className="form-control" placeholder="Type in new Password" id='nyu' required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Confirm Password</label>
                                                            <div className="m-t-10">
                                                                <input type="password" className="form-control" placeholder="Re-Type Password" id='nyuvalid' required />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div>
                                                                <button type="submit" className="btn btn-primary waves-effect waves-light">
                                                                    Submit
                                                                </button>
                                                                <button type="reset" className="btn btn-default waves-effect m-l-5">
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer"> © 2016 WebAdmin - All Rights Reserved. </footer>
        </div>
    );
}

export default AdminChangePassword;
