import {useState,useEffect,React} from 'react'
function Admindamageitem()
{
const [data,setdata]=useState([]);

async function loaddata()
{
     var a=await fetch(process.env.REACT_APP_URL+"Admincreateuser")

             var b=await a.json();
             var c=b.recordset;
             console.log("only recordset",c);

setdata(c);
}	

useEffect(()=>{loaddata()},[])


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Damaged Items Details</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Damaged Items Details</h4>
										<hr width="100%" />
										
										<div class="row">
										
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Date</label>
															 
															 <input type='date' class="form-control" />
															
																 
																 
														</div>
														

														
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
													
														
														<div class="form-group">
															<label>To</label>
															<div>
                                                           
															<select class="form-control">
                                                            <option>--Select--</option>
                                                            <option value="Sangeeta">Sangeeta Srivastava</option>
                                                            <option value="Divyanshi">Divyanshi Srivastava</option>
                                                            <option value="Priyank">Priyank Srivastava</option>
                                                            <option value="Mansi">Mansi Srivastava</option>
                                                            <option value="Sultanpur">Sultanpur</option>
                                                            <option value="BP">Business Promotion</option>
                                                            <option value="Damage">Damaged</option>
                                                            <option value="Expiry">Expired</option>
                                                            </select>
																	
																
															</div>
														</div>
														
                                      
														
														</form>
														
														
														
														</div>
														
														</div>
														
													
												</div>
                                                <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light">
                                                            Add Items</button>
                                                        <button type="reset"
                                                            className="btn btn-primary waves-effect m-l-5" style={{marginLeft:742.3}}>
                                                            Submit</button>
                                                    </div>
                                                </div>

											</div>
                                            
										</div>
									</div>
                                    
								</div>
                                
							</div>
                            
						</div>
                        
					</div>
				</div>
    )

}
export default Admindamageitem;