import React,{useState,useEffect} from 'react'
function Adminpanelapprove()
{

var [mydata,setmydata]=useState([]);
var option=1;
var Search='';//
function Searchdata(id)
{
	
   var rdb=document.getElementById(id);

   if(rdb.checked==true)
   {
  option=2;
  Search=id;
  loaddata();
   }
    

  // alert(rdb.checked+id);
}

	async function loaddata()
  {
	  if(option==1)
	     Search=document.getElementById('searchtempid').value;

	console.log(Search);
   var x=await fetch(process.env.REACT_APP_URL+"Adminpanelapprove?searchitem="+Search+"&option="+option);
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata();},[]);

function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }



  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Panel Approve</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Panel Approve</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
																<input type="button" class="btn btn-primary waves-effect waves-light" value="Download" style={{marginLeft:30}}/>
																	

                                                                    <input type="button" class="btn btn-primary waves-effect waves-light" value="Submit" style={{marginLeft:700}} onClick={display} / >
																	
															</div>
														</div> 
                                                        
                                                       
                                                        	
                                                          
													
												</div>

                                        
										<div class="row">
                                        	<div class="form-group">
															<div>
																<input type="radio" id="all" onChange={()=>{alert()}} name="fav_language" style={{marginLeft:30}}/>
                                                                 <label for="html">All</label>
                                                                 <input type="radio" id="branch" onChange={()=>{Searchdata('branch')}} name="fav_language" style={{marginLeft:20}} />
                                                                 <label for="html">Branch</label>
                                                                 <input type="radio" id="store" onChange={()=>{Searchdata('store')}}  name="fav_language" style={{marginLeft:20}} />
                                                                 <label for="html">Store</label>
                                                                 <input type="radio" id="depot" onChange={()=>{Searchdata('depot')}}  name="fav_language" style={{marginLeft:20}} />
                                                                 <label for="html">Depot</label>	
                                                                 <input type="radio" id="ds" onChange={()=>{Searchdata('ds')}}  name="fav_language" style={{marginLeft:20}} />
                                                                 <label for="html">Direct Seller</label>			
                                                                </div>
																  <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input id='searchtempid' type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
														</div> 
                                                       </div>

                                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Update User Login</h4> */}
                                       
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Approved</th>
												    <th>Applicant ID</th>
													<th>Applicant Type</th>
													<th>User Id</th>
													<th>Applicant Name</th>
													<th>Panel Name</th>
													
													<th>Mobile No</th>
                                                    <th>Password</th>
                                                    <th>Approved</th>
                                                    <th>Edit</th>
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													<td><input type="checkbox"  checked={row.IsApproved==="Y"?'true':'false'} /> </td>
                                                    <td>{row.applicantid}</td>
													<td>{row.applicanttype}</td>
                                                    <td>{row.tempid}</td>
													<td>{row.applicantname}</td>
													<td>{row.Name}</td>
													<td>{row.MobileNo}</td>
                                                    <td>{row.password}</td>
                                                    <td>{row.IsApproved}</td>
                                                    <td>Edit</td>
                                                   
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							
						
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Adminpanelapprove;