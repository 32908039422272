import logo from './logo.svg';
import React, { StrictMode } from 'react';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';

import DStopbar from './DirectSeller/DStopbar';
import DSleftmenu from './DirectSeller/DSleftmenu';

import StoreTopBar from './Store/StoreTopBar';
import StoreLeftmenu from './Store/Storeleftmenu';

import Depottopbar from './Depot/Depottopbar';
import Depotleftmenu from './Depot/Depotleftmenu';

import Branchtopbar from './Branch/Branchtopbar';
import Branchleftmenu from './Branch/Branchleftmenu';

import Login from './login';

import Dsroutes from './Dsroutes'
import Depotroutes from './Depotroutes';
import Storeroutes from './Storeroutes';
import Branchroutes from './Branchroutes';
import NotFound from './NotFound.js';
import AdminTopBar from './Admin/Admintopbar';
import AdminLeftmenu from './Admin/Adminleftmenu';
import Adminroutes from './Adminroutes';

import { Adminlogin } from './Admin/Adminlogin.js';
import { Saleinvoicepanel } from './Reports/saleinvoicepanel.js';
import Invoice from './invoicebill.js';
//import Adminlogin from './Admin/Adminlogin.js';
import StoreRegistrationForm from './Registration/Storeregistrationform.js';
import DepotRegistrationForm from './Registration/DepotRegistrationForm.js';
import BranchRegistrationForm from './Registration/BranchRegistrationForm.js';

import RegistrationForm from './Registration/RegistrationDsform.js';


function App() {
  console.log("FINANCIALY YEAR=",process.env.REACT_APP_FINANCIAL_YEAR);
  console.log("token=",localStorage.getItem("token"));
  useEffect(() => {
    console.log(localStorage.getItem("islogin"), "login");
   

  }, [])

  return (

    <BrowserRouter>
      
          <Routes>
          {/* <Route path="/invoicebill" element={<Invoice />} /> */}
          <Route path="/Registrationform" element={<RegistrationForm />} />
        <Route path="/StoreRegistrationForm" element={<StoreRegistrationForm />} />
        <Route path="/DepotRegistrationForm" element={<DepotRegistrationForm />} />
        <Route path="/BranchRegistrationForm" element={<BranchRegistrationForm />} />
        {/* <Route path="/adminlogin" element={<Adminlogin />} /> */}
        {/* <Route path="/" element={<Login />} /> */}
      </Routes>
      {/* {localStorage.clear()}
      
      {/* {console.log("tempID", (localStorage.getItem("tempID") + localStorage.getItem("applicantType")))} */}
      {/* {(localStorage.getItem("islogin") === 'false' || localStorage.getItem("islogin") == null) && localStorage.getItem("isadminlogin") == null && <Login />} */}
      {/* {localStorage.getItem("islogin") == false || localStorage.getItem("islogin") == null && <Adminlogin />} */}

      {console.log("tempID", (localStorage.getItem("tempID")))}
      {console.log("applicantType", (localStorage.getItem("applicantType")))}
      {console.log("applicantType is admin?", (localStorage.getItem("applicantType") == 'Admin'))}
      {/* 
        : (!(localStorage.getItem("adminStatus") == 'wanttologin')) && */}
       { <div>
          {/* {console.log("status", (localStorage.getItem("adminStatus")))}
          {console.log("applicantType", (localStorage.getItem("applicantType")))}
          {console.log("panelstatus", (localStorage.getItem("panelstatus")))}
          <Login /> */}
          {
            localStorage.getItem("applicantType") == 'Admin' ? (<><AdminTopBar /><AdminLeftmenu /><Adminroutes /></>):
            (localStorage.getItem("applicantType") == "DS" ) ? (<><DStopbar /><DSleftmenu /> <Dsroutes /></>) :

              (localStorage.getItem("applicantType") == "Store" ) ? (<><StoreTopBar /><StoreLeftmenu /><Storeroutes /></>) :
                (localStorage.getItem("applicantType") == "Depot" ) ? (<><Depottopbar /><Depotleftmenu />  <Depotroutes /></>) :
                  (localStorage.getItem("applicantType") == "Branch" ) ? (<><Branchtopbar /><Branchleftmenu /> <Branchroutes /></>) :
                  <Login />
          }

        </div>

      }

      
    </BrowserRouter>

  )

}

export default App;
