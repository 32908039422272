import { useState,useEffect ,React} from "react";
import axios from 'axios';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditItem()
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    let z;
    async   function loaddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"pd?id=2082");
        var y=await x.json();
          z=await y.recordset;
          console.log('data10',z );
          setdata(z);
         console.log('data2',mydata );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    
    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Item </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add / Edit Item</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Item data </button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Item Name</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Video URL(Path)</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Category Id</label>
                                                    <select class="form-control">
															   <option value="1">Fmcg Product</option>
															   <option value="2">Healthcare Product</option>
                                                               <option value="3">Home Appliances</option>
                                                               <option value="4">textiles</option>
                                                               <option value="5">Stationary</option>
                                                               <option value="6">Accessories</option>
                                                               <option value="7">ALOEVERA &amp; G-9 GOLD COMBO</option>
                                                               <option value="8">RBO</option>
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Sub Category Id</label>
                                                    <select class="form-control">
													    <option value="1">eatable</option>
														<option value="2">non eatable</option>
                                                        <option value="3">Male</option>
                                                        <option value="4">Female</option>
                                                        <option value="5">Kids</option>
                                                        <option value="6">READABLE</option>
                                                        <option value="7">WRITEABLE</option>
                                                        <option value="8">Electronics</option>
                                                        <option value="9">Floor Cleaner</option>
                                                        <option value="10">PVC</option>
                                                        <option value="11">Bed And living</option>
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:832}}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditItem;