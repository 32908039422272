import {useState,useEffect,React} from 'react'
function Admincreateuser()
{
const [data,setdata]=useState([]);
const [mydata,setmydata]=useState([]);

   async   function loaddata()
    {

         var txtuserid=document.getElementById('txtuserid').value;
          var txtusername=document.getElementById('txtusername').value;
		    var txtuserpassword=document.getElementById('txtuserpassword').value;
		  var txtusermobile=document.getElementById('txtusermobile').value;
		  var txtuserrole=document.getElementById('txtuserrole').value;
 
     var x= await  fetch(process.env.REACT_APP_URL+"Admincreateuserinsertdata?userid="+txtuserid+"&username="+txtusername+"&userpassword="+txtuserpassword+"&usermobile="+txtusermobile+"&userrole="+txtuserrole,{
     method:"POST",
     headers:{"Content-Type":"application/json"},
     body: JSON.stringify({
        
     })
     } )
        var y=await x.json();
         var z=await y.recordset;
         console.log("z="+z);
      
    }

	async function loaddata2()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Admincreateuser');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setdata(a);
    console.log("data",data);
  }
 
  useEffect(()=>{loaddata2();},[]);






    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">User Management</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">User Registration</h4>
										<hr width="100%" />
										 {(mydata!=null && mydata.length>0) &&
										  mydata.map((mydata,index)=>{
											return(  
										<div class="row">
										<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	User Registration</button>
															</div>
														</div>
														
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>User Name </label>
															 <label class="form-control" id='txtusername'>
															 {/* {mydata.TempID} */}
															 </label>
																 
																 
														</div>
														<div class="form-group">
															<label>Mobile No</label>
															
																<label class="form-control" id='txtusermobile'>
																	 {/* {mydata.ApplicantDob} */}
																</label>
															
															
														</div>
														<div class="form-group">
															<label>Re-enter Password</label>
															<label class="form-control">
																	 {}
																</label>
														</div>

														

														
														
														
													
														
														
													</form>
												</div>
											</div> 

					                        
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>User Id</label>
															<div>
																<label class="form-control" id='txtuserid'>
																	{/* {mydata.ApplicantType} */}
																</label>
															</div>
														</div>
														<div class="form-group">
															<label>Password</label>
															<div>
															<label class="form-control" id='txtuserpassword'>
																{/* {mydata.ApplicantGuardionName}	 */}
																</label>
																
																	
																
															</div>
														</div>
														<div class="form-group">
															<label>User Role Name</label>
															<div>
                                                           
															<select class="form-control" id='txtuserrole'>
                                                                <option value="User">User</option>
                                                                <option value="Admin">Admin</option>
                                                            </select>
																	
																
															</div>
														</div>
														
                                      
														
														</form>
														
														
														
														</div>
														
														</div>
														
													
												</div>
												)
										  })
					}		 

												<div class="form-group">
															<label>Assign Permission</label>
															
																<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th></th>
												    <th>Sr No.</th>
													<th>Form Id</th>
													<th>Form Name</th>
													<th>Description</th>
													
                                                    
													
													
												</tr>
											</thead>
                                            
											<tbody>
												 {(data!=null && data.length>0 ) &&
                                                               data.map((mydata,index)=>{
                                                                   return(
                                                                
											
                                              <tr>
													<td><input type="checkbox" /></td>
													<td>{index+1}</td>
                                                    <td>{mydata.FormId}</td>
													<td>{mydata.FormName}</td>
                                                    <td>{mydata.Description}</td>
													
                                                    
												</tr>
											
                                             
											
										
                                        												
											 )
												})
										} 
                                        </tbody>
										</table>
                                       
																
														</div>

                                                <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light">
                                                            Submit</button>
                                                        <button type="reset"
                                                            className="btn btn-default waves-effect m-l-5">
                                                            Cancel</button>
                                                    </div>
                                                </div>

											</div>
                                            
										</div>
									</div>
                                    
								</div>
                                
							</div>
                            
						</div>
                        
					</div>
				</div>
    )

}
export default Admincreateuser;