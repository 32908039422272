import React,{useState,useEffect} from 'react'
function AdminDsmonthlystatement()
{


    var [mydata,setmydata]=useState([]);


 async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'monthlystate?month=5&year=2023&dsid=ds2081');
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
   
   console.log("mydata",mydata);
  }
  useEffect(()=>{loaddata();});


   return(
            <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Direct Seller Monthly Statement </h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Direct Seller Monthly Statement</h4>
										<hr width="100%"/>
										<div class="row">
                                            
                                        
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Month</label>
															<select class="form-control">
															   <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
															</select>
														</div>
														
														
													
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Year</label>
															<select class="form-control"><option>2020</option>
																<option>2021</option>
																<option>2022</option>
																<option>2023</option>
																<option>2024</option>
															</select>
														</div>
													
													
														
													</form>
												</div>
											</div>
                                            <div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>DS ID</label>
															<input type='text' class="form-control" />
																
														</div>
													
													
														
													</form>
												</div>
											</div>
                                             <div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
													<div class="form-group">
															<div>
																<button type="submit" 
																	class="btn btn-primary waves-effect waves-light"  style={{marginLeft:250,marginTop:27}}>
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5" style={{marginLeft:15,marginTop:27}}>
																	Cancel</button>
															</div>
														</div>
													
													
														
													</form>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Results </h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												     <th>S No.</th>
													<th>DSNAME</th>
													<th>DS No</th>
													<th>Business Value</th>
													<th>Commission(%)</th>
													<th>Commission Amt</th>
													
												</tr>
											</thead>
											
												
											
											
												
												{/* {
											  
                                               mydata.length>0 && */}
											   <tbody>
                                             <tr>
													<td>{1}</td>
													<td>
                                                    {/* {mydata[0].DSNAME} */}
                                                    </td>
													<td>
                                                    {/* {mydata[0].DSID} */}
                                                    </td>
													<td>
                                                    {/* {mydata[0].TotalBusiness} */}
                                                    </td>
													<td>
                                                    {/* {mydata[0].TotalBusinessSlab} */}
                                                    </td>
													<td>
                                                    {/* {mydata[0].TotalBusinessValue} */}
                                                    </td>
													
												</tr>
											  <tr>
													{/* <td>{2}</td>
													<td>{mydata[0].LeftNAME}</td>
													<td>{mydata[0].LEFTID}</td>
													<td>{mydata[0].Leftbusiness}</td>
													<td>{mydata[0].LeftBusinessSlab}</td>
													<td>{mydata[0].LeftBusinessValue}</td> */}
													
												</tr>
											  <tr>
													{/* <td>{3}</td>
													<td>{mydata[0].RightName}</td>
													<td>{mydata[0].RIGHTID}</td>
													<td>{mydata[0].Rightbusiness}</td>
													<td>{mydata[0].RightBusinessSlab}</td>
													<td>{mydata[0].RightBusinessValue}</td>
													 */}
												</tr>
												 <tr>
													{/* <td>{4}</td>
													<td>Selfless</td>
													<td>{mydata[0].DSID}</td>
													<td>{mydata[0].Selfbusiness}</td>
													<td>{mydata[0].SelfBusinessSlab}</td>
													<td>{mydata[0].SelfBusinessValue}</td> */}
													
												</tr>
											 
                                              
												</tbody>
                                                {/* } */}
											
										</table>
									</div>
								</div>
							</div>
						</div>
						  
    <div class="page-content-wrapper ">
					
	<div class="container">
	<div class="row">
   <div class="col-md-12">
	<div class="panel panel-primary">
	<div class="panel-body">
    <table class="table">
	
      <thead>
        <tr>
          <th>Other Commission</th>
          
        </tr>
      </thead>
	  {/* {
											  
                                               mydata.length>0 && */}
      <tbody>
        <tr>
          <td>Ds Commission Amount-</td>
          {/* <td>{mydata[0].TotalBusinessValue}</td> */}
        </tr>
        <tr>
          <td>Less</td>
          {/* <td>{mydata[0].TotalValue}</td> */}
        </tr>
        <tr>
          <td>Diffrence Income Amt.</td>
          {/* <td>{mydata[0].ComissionAmt}</td> */}
        </tr>
		 <tr>
          <td>Incmome Awareness</td>
          {/* <td>{mydata[0].incomeawareness}</td> */}
        </tr>
		 <tr>
          <td>Royality Income</td>
          {/* <td>{mydata[0].RoyalityIncome}</td> */}
        </tr>
		 <tr>
          <td>Technical Income</td>
          {/* <td>{mydata[0].TechnicalIncome}</td> */}
        </tr>
		 <tr>
          <td>Bonus</td>
          {/* <td>{mydata[0].Bonusamt}</td> */}
        </tr>
		 <tr>
          <td>Faimly Security</td>
          {/* <td>{mydata[0].FaimlySecurity}</td> */}
        </tr>
		 <tr>
          <td>Dress Code Bonus</td>
          {/* <td>{mydata[0].dcbonus}</td> */}
        </tr>
		 <tr>
          <td>Business Growth Bonus</td>
          {/* <td>{mydata[0].bgbonus}</td> */}
        </tr>
		 <tr>
          <td>Dynamic Team Education Bonus</td>
          {/* <td>{mydata[0].dtebonus}</td> */}
        </tr>
		 <tr>
          <td>Child Health & Education Bonus</td>
          {/* <td>{mydata[0].chebonus}</td> */}
        </tr>
		<tr>
          <td>Bike Bonus</td>
          {/* <td>{mydata[0].bikebonus}</td> */}
        </tr>
        <tr>
          <td>Tour & Travel Bonus</td>
          {/* <td>{mydata[0].ttbonus}</td> */}
        </tr>
        <tr>
          <td>House Bonus</td>
          {/* <td>{mydata[0].housebonus}</td> */}
        </tr>
		 <tr>
          <td>Luxury Car Bonus</td>
          {/* <td>{mydata[0].lcbonus}</td> */}
        </tr>
		 <tr>
          <td>JMVD Brand Ambassador Bonus</td>
          {/* <td>{mydata[0].jbabonus}</td> */}
        </tr>
		 <tr>
          <td>Saving & Investment Bonus</td>
          {/* <td>{mydata[0].sibonus}</td> */}
        </tr>
		 <tr>
          <td>Future & Retirement Bonus</td>
          {/* <td>{mydata[0].frbonus}</td> */}
        </tr>
		 <tr>
          <td>Matching Point Income</td>
          {/* <td>{mydata[0].mp}</td> */}
        </tr>
		 <tr>
          <td>Matching Point Awareness</td>
          {/* <td>{mydata[0].mpa}</td> */}
        </tr>
		 <tr>
          <td>Direct Income</td>
          {/* <td>{mydata[0].di}</td> */}
        </tr>
		 <tr>
          <td>Direct Income Awareness</td>
          {/* <td>{mydata[0].dai}</td> */}
        </tr>
		 <tr>
          <td>Commission</td>
          {/* <td>{mydata[0].fc}</td> */}
        </tr>
		 <tr>
          <td>Repurchase Income(10%)</td>
          {/* <td>{mydata[0].repur}</td> */}
        </tr>
		 <tr>
          <td>Total Commission </td>
          {/* <td>{mydata[0].FinalCommision}</td> */}
        </tr>
		 <tr>
          <td><strong>TDS Amt ( 5.00 %) </strong></td>
          {/* <td><strong>{mydata[0].TDSAmt}</strong></td> */}
        </tr>
		 <tr>
          <td><strong>Net Commission </strong></td>
          {/* <td><strong>{mydata[0].NetCommission}</strong></td> */}
        </tr>
		 <tr>
          <td><strong>Previous Commission </strong></td>
          {/* <td><strong>{mydata[0].PreviousCommission}</strong></td> */}
        </tr>
        
      </tbody>
      {/* } */}
      <tfoot>
        <tr>
          <td><strong>Net Payable </strong></td>
          {/* <td><strong>{mydata[0].NetCommission} + {mydata[0].PreviousCommission}</strong></td> */}
        </tr>
      </tfoot>
    </table>
	</div>
	</div>
	</div>
	</div>
  </div>
	</div>
					
					
					
					
					
					
					
					
				</div>
			</div>
			</div>
		</div>)
}

export default AdminDsmonthlystatement;