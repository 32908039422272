import { useState,useEffect ,React} from "react";
import axios from 'axios';

function AdminBDSStockReport()
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    let z;
    async   function loaddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"pd?id=2082");
        var y=await x.json();
          z=await y.recordset;
          console.log('data10',z );
          setdata(z);
         console.log('data2',mydata );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    
    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Stock Reports</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Stock Report</h4>
                                <hr width="100%"/>
                                <div className="row">
                                 <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-default waves-effect waves-light " style={{marginLeft:30}}>
                                                          <input type="checkbox"/>Branch</button>
                                                        <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                           <input type="checkbox"/>Depot</button>
                                                            <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                           <input type="checkbox"/>Store</button> 
                                                            <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                           <input type="checkbox"/>Direct Seller</button>     
                                                        
                                                    </div>
                                                </div>
                               <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:30}}>
                                                            Download</button>
                                                        
                                                    </div>
                                                </div> 
                                </div>

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										{/* <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th></th>
												    <th>Applicant Id</th>
													<th>Batch No</th>
													<th>Product Name</th>
													<th>Quantity</th>
                                                    <th>Rate</th>
                                                    <th>Amount</th>
                                                    <th>BV</th>
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td><input type="checkbox"/></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table> */}
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminBDSStockReport;