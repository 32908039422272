import React, {useState,useEffect} from 'react'

function AdminDsBusinessVolume()
{  

	var [mydata,setmydata]=useState([]);

	async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'yourbusiness?Fromdate=2023-05-01&ToDate=2023-05-30&ApplicantID=2082&CurrentIDs=2082&option=1');
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
  useEffect(()=>{loaddata();});




    return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">DIRECT SELLER BUSINESS VOLUME</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">DIRECT SELLER BUSINESS VOLUME</h4>
										<hr width="100%" />
										
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Enter Your downline Direct Seller ID </label>
															<input type='text' className='form-control' />
															
												{
											  
                                               mydata.length>0 &&
															 <label  class="form-control" >{mydata[0].tempid} </label>
												} 
														</div>
														<div class="form-group">
															<label>From Date</label>
															
																<div>
																	<div>
																<input type="date" class="form-control" required
																	 />
															</div>
																</div>
															
															
														</div>
														
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
													<div class="form-group">
															<label></label>
															<div>
																<div>
																<input type="" class="form-control" />
															</div>
															</div>
														</div>

														<div class="form-group">
															<label>To Date</label>
															<div>
																<div>
																<input type="date" class="form-control" required
																	 />
															</div>
															</div>
														</div>

														
														
														
														</form>
														
														
														
														
														</div>
														
														</div>
														
													
												</div>

								
											<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5">
																	Cancel</button>
															</div>
														</div>
												
										
											</div>
										</div>
									</div>
								</div>
							
							<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">DS Business Volume </h4>
										  <div class="container">
    
    <div class="row">
      <div class="col-md-12">
	  <div></div>
	  <div class="col-md-4">
        <div class="form-group">
          <label for="box1">Welcome-</label>
          {
			mydata.length>0 &&
	 <label  class="form-control" >{mydata[0].ApplicantName} </label>
												} 
        </div>
		</div>
     
         <div class="col-md-4">
        <div class="form-group">
          <label for="box1">Total Business Volume</label>
          {
			mydata.length>0 &&
	 <label  class="form-control" >{mydata[0].TotalBusiness} </label>
												} 
        </div>
		</div>
     
         <div class="col-md-4">
        <div class="form-group">
          <label for="box1">Self Business Volume</label>
           {
			mydata.length>0 &&
	 <label  class="form-control" >{mydata[0].Selfbusiness} </label>
												} 
        </div>
		</div>
      </div>
	  
    </div>

  </div>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>S No.</th>
													<th>DS ID</th>
													<th>DS Name</th>
													<th>Position</th>
													<th>Self Business Volume</th>
													<th>Total Business Volume</th>
													
												</tr>
											</thead>
											{
			                               mydata.length>0 &&
											<tbody>
										
                                          <tr>
													<td>{1}</td>
													<td>{mydata[0].tempid}</td>
													<td>{mydata[0].ApplicantName}</td>
													<td>{mydata[0].Position}</td>
													<td>{mydata[0].Selfbusiness}</td>
													<td>{mydata[0].TotalBusiness}</td>
													<td><button  class="btn btn-danger">Downline</button></td>
													
												</tr>

												 <tr>
													<td>{2}</td>
													<td>{mydata[0].tempid}</td>
													<td>{mydata[0].LeftChild}</td>
													<td>{mydata[0].Position}</td>
													<td>{mydata[0].Selfbusiness}</td>
													<td>{mydata[0].TotalBusiness}</td>
													<td><button  class="btn btn-danger">Downline</button></td>
													
												</tr>
												<tr>
													<td>{3}</td>
													<td>{mydata[0].tempid}</td>
													<td>{mydata[0].RightChild}</td>
													<td>{mydata[0].Position}</td>
													<td>{mydata[0].Selfbusiness}</td>
													<td>{mydata[0].TotalBusiness}</td>
													<td><button  class="btn btn-danger">Downline</button></td>
													
												</tr>
												
                                              
												
                                                
                                             
											</tbody>
											}
										</table>
									</div>
								</div>
							</div>
						</div>
							
							
							</div>
						</div>
					</div>


				</div>
	)
}
export default AdminDsBusinessVolume;