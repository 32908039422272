import React,{useState,useEffect} from 'react'
function Directseller()
{

    var [mydata,setmydata]=useState([]);
    
    
        async function loaddata()
      {
       var x=await fetch(process.env.REACT_APP_URL+'Adminchangepermission');
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);
    
    
    
    
      return(
            <div class="content-page">
                <div class="content">
                    <div class="">
                        <div class="page-header-title">
                            <h4 class="page-title">Direct Seller</h4>
                            
                        </div>
                    </div>
                    
                    <div class="page-content-wrapper ">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body">
                                            <h4 class="m-t-0 m-b-30">Direct Seller</h4>
                                            <hr width="100%" />
                                            
                                            <div class="row">
                                               <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Direct Seller ID or Direct Seller Name</label>
                                                    <input type="text" placeholder="Enter Ds Id Or Ds Name" class="form-control" />
															
																 

															
                                                </div>
                                                
                                              <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                            Submit</button>  
                                                             
                                                    </div>
                                                </div>  
                                                
                                            </form>
                                        </div>
                                    </div>
                                                
                                                           
                                                                
                                                              
                                                        
                                                    </div>
    
                                                 <div class="row">
                                <div class="col-md-12">
                                    <div class="panel panel-primary">
                                        <div class="panel-body">
                                            {/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
                                            <table id="datatable-buttons"
                                                class="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>DS ID</th>
                                                        <th>Applicant Name</th>
                                                        <th>Password</th>
                                                        <th>Is Approved</th>
                                                        <th>Sponsor Name</th>
                                                        <th>Awareness</th>
                                                        <th>Profile</th>
                                                        <th>Group</th>
                                                        <th>DS Business</th>
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mydata.length>0 &&
                                                  mydata.map((row,index)=>{
                                                  
                                                  return(	
                                                
                                                  <tr>
                                                        <td>{index+1}</td>
                                                         <td>{row.FormId}</td>
                                                        <td>{row.UID}</td>
                                                        <td>{row.Username}</td>
                                                        <td>{row.Userid}</td>
                                                        <td>{row.FormId}</td>
                                                        <td>{row.UID}</td>
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /></td>
                                                        
                                                        
                                                        <td><input type="button" value="Edit" className="btn btn-primary waves-effect waves-light" /> </td>
                                                       
                                                        
                                        
                                                    </tr>
                                                
                                                 ) 
                                                  })
                                                    
                                                    }
                                                </tbody>
                                            
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                            
                                            
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                
                                
                            
                                
                                </div>
                            </div>
                        </div>
    
    
                    </div>
        )
    
    
      
    }

export default Directseller;