import { useState,useEffect ,React} from "react";
import axios from 'axios';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditSaleInvoice(){
   //from database
    const [item,setitem]=useState([]);
    const [panelchild,setpanelchild]=useState([]);

    //to database
    const [jsonsaleitems,setjsonsaleitems]=useState([]);
    const [jsonsaleinvoice,setjsonsaleinvoice]=useState([]);

    const [totalamount,settotalamount]=useState(0);
    const [totalbv,settotalbv]=useState(0);
   
    function setcurrentdate()
    {
         const now = new Date();
        
        // Format the date as YYYY-MM-DD
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
        
        // Set the value of the input element
        document.getElementById('txtinvoicedate').value = currentDateTime;
        document.getElementById('txtinvoicedate').readOnly=true;

    }
    
    async   function loaditemdata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"getitemsbyapplicantid?applicanttype="+localStorage.getItem("applicantType")+"&applicantid="+localStorage.getItem("applicantID"));
        var y=await x.json();
         var z=await y.recordset;
          console.log('data10',z );
          setitem(z);
         console.log('item',item );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    async   function loadpanelchilddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"getpanelchildbyparentid?parentapplicantid="+localStorage.getItem("applicantID"));
        var y=await x.json();
         var z=await y.recordset;
          console.log('panelchild',z );
          setpanelchild(z);
         console.log('panelchild',item );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    async   function savedatatodatabase()
    {
        savedata();
         console.log("jsondata",jsonsaleinvoice);
         try{
     var x= await  fetch(process.env.REACT_APP_URL+"saveinvoice",{
     method:"POST",
     headers:{"Content-Type":"application/json"},
     body: JSON.stringify({'jsondata':jsonsaleinvoice})
     } );
     if (!x.ok) {
    throw new Error(`HTTP error! Status: ${x.status}`);
  }
        var y=await x.json();
         var z= y.recordset;
         console.log("z="+z);
         }
         catch(err){
            console.log("show error",err);
         }
      
    }
    function additem(currentitem,e,index)
    { 
        // const isChecked = e.target.checked;
        // const inputValue = document.getElementById(`txtsaleqty-${index}`).value;
     
        // alert(e.target.id+inputValue);
        //alert("hi");
        var saleqty=parseInt(document.getElementById("txtsaleqty-"+index).value);
       

       // alert("qty"+saleqty+"avl qty"+currentitem.Quantity);
        if (isNaN(saleqty)) {
            alert("Please enter a valid quantity.");
            e.target.checked = false;//checkbox
            return; // Exit function if saleqty is not a number
        }
       else if(saleqty>currentitem.Quantity)
           {
            alert("Please check Available Quantity of "+currentitem.itemname);
            
                e.target.checked = false; // Unselect the checkbox
            
           }

       else if (jsonsaleitems.includes(currentitem)) //true or false(uncheck)
           {
            //101 amt=100
            //totamt=totamt-amt;
            //settotamt(totamt-amt);300-100
            settotalbv(totalbv-(currentitem.businessvolume*currentitem.saleqty));
            settotalamount(totalamount-(currentitem.SellingPrice*currentitem.saleqty));
            
     setjsonsaleitems( jsonsaleitems.filter((row)=> row.ItemId !== currentitem.ItemId  ));
          }
           else {

            currentitem.saleqty=saleqty;
            currentitem.InvoiceType="S";
            currentitem.FromApplicantId=localStorage.getItem("applicantID");

            setjsonsaleitems([...jsonsaleitems,currentitem]);
              settotalamount(totalamount+(currentitem.SellingPrice*currentitem.saleqty));
              settotalbv(totalbv+(currentitem.businessvolume*currentitem.saleqty));
            //totamt=totamt+amt;
            //settotamt(totamt+amt);//100+200=300
          }  
          alert("additem"+JSON.stringify(jsonsaleitems)); 
    }
    function savedata()
    {
       //var appid= document.getElementById("ddlpanelchild").value;
       //alert("id"+appid);
     //  alert("ji"+ document.getElementById("ddlpanelchild").value);
        var saleinvoice={
            "StorageLocationId": 1,
            "FromApplicantId": localStorage.getItem("applicantID"),
            "ApplicantId":(document.getElementById("ddlpanelchilddepot").value==0?
                          document.getElementById("ddlpanelchildbranch").value:
                          document.getElementById("ddlpanelchilddepot").value),
            "InvoiceType": document.getElementById("txtinvoicetype").value,
            "InvoiceNo": document.getElementById("txtinvoiceno").value,
            "InvoiceDate": document.getElementById("txtinvoicedate").value,
            "Reference": document.getElementById("txtreference").value,
            "FormNo": null,
            "PanNo": null,
            "VatNo": null,
            "VehicleNo": null,
            "Weight": null,
            "IsDelivered": "N",
            "Remark": null,
            "status": "Y",
            "packets": 1,
            "transportname": null,
            "DeliverAt": null,
            "invoiceamt": 5000.00,
            "invoicebv": 2089.00,
            "ip": null,
            "FinancialYear": 8,
            "GST": "Y",
            "FrieghtCharges": null,
            "LPCharges": null,
            "InsuranceCharges": null,
            "OtherCharges": null,
            "ReverseCharge": null,
            "grno": null,
            "Dos": null,
            "SaleItems":jsonsaleitems
        }
//console.log(saleinvoice);
 
        setjsonsaleinvoice([...jsonsaleinvoice,saleinvoice])
       // console.log("jsondata",jsonsaleinvoice);
     //  savedatatodatabase();
    }
    useEffect(()=>{
      
        loadpanelchilddata();
       setcurrentdate();
           
    },[])

   async function Createinvoiceno(){

       //alert("hiii");
        var ddlinvoicetype=document.getElementById('txtinvoicetype').value;
        var invoiceno=document.getElementById('txtinvoiceno');
        var FinancialYear=8;
        var FromApplicantId=localStorage.getItem('applicantID');
        var Reference=document.getElementById("txtreference");


        var x= await  fetch(process.env.REACT_APP_URL+"getinvoicecount?InvoiceType="+ddlinvoicetype+"&FromApplicantId="+FromApplicantId+"&FinancialYear="+FinancialYear);
        var y= await x.json();
         var z= y.recordset;
          console.log('getinvoicecount',z );

          invoiceno.value="JMVD999999-"+ddlinvoicetype+"I"+z[0].invoiceno;
          invoiceno.readOnly=true;
          Reference.value=z[0].invoiceno;
          Reference.readOnly=true;
   //alert(invoiceno);
          

    }

    function Displaydisable(whois){
        // alert('hi');
        var Branch=document.getElementById('ddlpanelchildbranch');
        var Depot=document.getElementById('ddlpanelchilddepot');

        if(whois=='branch'){
            Branch.disabled=false;
            Depot.disabled=true;
        }
        else{
            Branch.disabled=true;
            Depot.disabled=false;
    }
    }

//    async  function Additems()
//       {
//         // var month=document.getElementById('txtmonth').value;
//         //  var year=document.getElementById('txtyear').value;
//         //  var state=document.getElementById('txtstate').value;
         
//         //   console.log(month,year,state);
//        var x=await fetch(process.env.REACT_APP_URL+'Additems');       
//        var y=await x.json();
//        var z=JSON.stringify(y.recordset);
//        var a=JSON.parse(z);
//        setitem(a);
//         console.log("item",item);
//       }
//       useEffect(()=>{
      
//         Additems();
       
           
//     },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Sale Invoice</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add /Edit Sale Invoice</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:800}}>
                                                        Go Back</button>       
                                                        
                                                    </div>
                                                </div> 

                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Invoice Date</label>
                                                    <input type="datetime-local" id="txtinvoicedate"  class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>


                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Invoice Number</label>
                                                    <input type="text" id="txtinvoiceno"  class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>



                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Sale Invoice Type</label>
                                                   <select class='form-control' id="txtinvoicetype" onChange={Createinvoiceno}>
                                                   <option value="0">---Select---</option>
                                                   <option value="S" >Sale Invoice</option>
                                                   <option value="T">Transfer Invoice</option>
                                                 
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>

                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Reference</label>
                                                   <input type="text" id="txtreference" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>

                            
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Depot Id</label>
                                                   <select type="date" id="ddlpanelchilddepot" class='form-control' onChange={()=>{Displaydisable('depot')}}>
                                                   <option>---Select---</option>
                                                   {
                                                   panelchild.length>0 &&
                                                   panelchild.map((row,index)=>{
                                                    if(row.ApplicantType==='Depot')
                                                        return(
                                                         <option value={row.ApplicantId}>{row.name}--({row.Tempid})</option>
                                                        )
                                                     })
                                                   }
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Return Invoice No.</label>
                                                    <input type="text" id="txtreturninvoiceno" class='form-control' placeholder="Return Invoice No." />
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Branch Name</label>
                                                   <select type="date" id="ddlpanelchildbranch" class='form-control' onChange={()=>{Displaydisable('branch')}}>
                                                   <option>---Select---</option>
                                                   {
                                                   panelchild.length>0 &&
                                                   panelchild.map((row,index)=>{
                                                    if(row.ApplicantType==='Branch')
                                                        return(
                                                         <option value={row.ApplicantId}>{row.name}--({row.Tempid})</option>
                                                        )
                                                     })
                                                   }
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                   
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Vehicle Number</label>
                                                   <input type="text" id="txtvehicleno" class='form-control'/>
                                                  
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Remark</label>
                                                   <input type="text" id="txtremark" class='form-control'/>
                                                  
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Total Packet</label>
                                                   <input type="text" id="txtpacket" class='form-control'/>
                                                  
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>



                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Transport Name</label>
                                                    <input type="text" id="txtTransport" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Is Block</label>
                                                   <select class='form-control'>
                                                   <option>Unblock</option>
                                                   <option>Block</option>
                                                   </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                  
                                      <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Unit</label>
                                                    <select class='form-control'>
                                                        <option>---Select Unit---</option>
                                                        <option>PCS</option>
                                                    </select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                           <input type="button" class="btn btn-primary waves-effect waves-light" value="Add Items" data-toggle="modal"
																		data-target=".bs-example-modal-lg" onClick={loaditemdata} style={{marginLeft:250,marginTop:49}} />
																		
																	
																	<div class="modal fade bs-example-modal-lg" tabindex="-1"
																		role="dialog" aria-labelledby="myLargeModalLabel"
																		aria-hidden="true">
																		<div class="modal-dialog modal-lg">
																			<div class="modal-content">
																				<div class="modal-header">
																					<button type="button" class="close"
																						data-dismiss="modal" aria-hidden="true">×</button>
																					<h4 class="modal-title" id="myLargeModalLabel">Select Item</h4>
																				</div>
																				<div class="modal-body">
																					<div className='Records'>
																						<div class="row">
																							<div class="col-md-12">
																								<div class="panel panel-primary">
																									<div class="panel-body">
                                                                                                    <label style={{color:'red'}}>Total Amount =</label>
                                                                                                    <label style={{color:'red', marginLeft:5}}>{totalamount}</label>
                                                                                                    <label style={{color:'red', marginLeft:5}}>Total Bv =</label>
                                                                                                    <label style={{color:'red', marginLeft:5}}>{totalbv}</label>
                                                                                                    <div class="table-container-Table">
																										<table id="datatable-responsive"
																											class="table table-striped table-bordered dt-responsive nowrap"
																											cellspacing="0" width="100%">
																											<thead>
																												<tr>
																													<th>Select</th>
                                                                                                                    <th>Sale Quantity</th>
																													<th>Item ID</th>
																													<th>Item Name</th>
																													<th>Item Rate</th>
                                                                                                                    <th>BV</th>
                                                                                                                    <th>Standard Price</th>
																													<th>Available Quantity</th>
																													
																													<th>Batch No</th>
																												</tr>
																											</thead>
																											<tbody>

											 {item.length>0 &&
                                                 item.map((row,index)=> {
                                             return ( <tr key={index}>
                                                      <td><input type="checkbox"  onChange={(e)=>additem(row,e,index)}/></td>
                                                      <td><input type="text" style={{width:75}} id={"txtsaleqty-"+index} /></td>
                                                      <td>{row.ItemId}</td>
                                                      <td>{row.itemname}</td>
                                                      <td>{row.StandardPrice}</td>
                                                      <td>{row.businessvolume}</td>
                                                      <td>{row.SellingPrice}</td>
                                                      
                                                      <td>{row.Quantity}</td>
                                                     
                                                      <td>{row.batchno}</td>
            
                                                         </tr>
                                                     );
                                                       })} 
                                                         </tbody>
														  
																										</table>
                                                                                                        </div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>   
                                                        
                                                        <button type="submit" onClick={savedatatodatabase}
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5,marginTop:49}} >
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2024 WebAdmin - All Rights
        Reserved. </footer>
</div>

)

}



export default AdminAddEditSaleInvoice;