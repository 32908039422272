import React,{useState,useEffect} from 'react'
function Adminpaymentdetails()
{


    var [mydata,setmydata]=useState([]);


 async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'monthlystate?month=5&year=2023&dsid=ds2081');
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
   
   console.log("mydata",mydata);
  }
  useEffect(()=>{loaddata();});


   return(
            <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Monthly Statement </h4>
						
					</div>
				</div>

                
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Monthly Statement</h4>
										
                                        <div class="row" style={{marginLeft:-18}}>
                                        	<div class="form-group">
															<div>
																
                                                                 <input type="checkbox" id="html" name="fav_language" style={{marginLeft:20}} />
                                                                 <label for="html">Direct Seller</label>			
                                                                </div>
														</div> 
                                                       </div>
                                                        <div class="row">
                                        	<div class="form-group">
															<div>
																<label style={{marginLeft:10,color:'#5cb45b'}}> IS PAID=
                                                                 <input type="checkbox" id="html" name="fav_language" style={{marginLeft:15}} />
                                                                 <label for="html">Yes</label>
                                                                  <input type="checkbox" id="html" name="fav_language" style={{marginLeft:15}} />
                                                                 <label for="html">No</label>	
                                                                 </label>		
                                                                </div>
														</div> 
                                                       </div>
                                                       
										<div class="row" style={{marginTop:-31}}>
											<div class="col-sm-6 col-xs-12">
                                            <div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label >Month</label>
															<select class="form-control">
															   <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
															</select>
														</div>
														
														
													
														<div class="form-group">
															<div>
																<button type="submit" 
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:10}}>
																	Submit</button>
																<button type="reset"
																	class="btn btn-default waves-effect m-l-5" style={{marginLeft:10}}>
																	Cancel</button>
															</div>
														</div>
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Year</label>
															<select class="form-control"><option>2020</option>
																<option>2021</option>
																<option>2022</option>
																<option>2023</option>
																<option>2024</option>
															</select>
														</div>
													
													
														
													</form>
												</div>
											</div>
										</div>
                                        <div class="row">
                                        	<div class="form-group">
															<div>
																 <label for="html"style={{marginLeft:20}}>Upload Files</label>
                                                                 <input type="file" id="html" name="fav_language" style={{marginLeft:20}} />
                                                                 			
                                                                </div>
                                                                <div>
                                                                    <button type="submit" 
																	class="btn btn-primary waves-effect waves-light" style={{marginLeft:250,marginTop:-58}}>
																	Upload</button>
                                                                </div>
														</div> 
                                                       
                                                       </div>

                                                        
                                                       <div class="row">
                                                        <div class="form-group">
															
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search By ID OR Name......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                       </div>
									</div>
								</div>
							</div>
						</div>
						

					
					
					
					
					
					
					
					
				</div>
			</div>
			</div>
		</div>)
}

export default Adminpaymentdetails;