import React,{useState,useEffect} from 'react'
function Admintransportdetail()
{

var [mydata,setmydata]=useState([]);


	async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Adminchangepermission');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata();},[]);




  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Transport Detail</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Edit Transport Detail</h4>
										<hr width="100%" />
										
										

                                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Enter Invoice No.</h4>
                                        <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                        <div class="row">
                                        	<div class="form-group">
															<div>
																<button type="submit" class="btn btn-primary waves-effect waves-light" style={{marginLeft:10}}>
																	Submit</button>
															</div>
														</div> 
                                                       
                                                        	
                                                          
													
												</div>
												<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										{/* <h4 class="m-b-30 m-t-0">Update User Login</h4> */}
                                        <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																{/* <div>
																 <input type="text" class="form-control" placeholder="Search......" aria-label="Search"  />
															</div> */}
															</div>
														</div>
										 <table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th></th>
												    <th>Invoice No</th>
													<th>Vehicle No</th>
													<th>Transpoert Name</th>
													<th>Packet</th>
													<th>GR NO</th>
													<th>Date of Supply</th>
													
													
												</tr>
											</thead>
											<tbody>
												{/* {mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	 */}
											
                                              <tr>
													
													<td><input type="checkbox"  /> </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
													<td></td>
													<td></td>
													<td></td>
									
												</tr>
											
                                             {/* ) 
                                              })
												
                                                } */}
											</tbody>
										
										</table> 
									</div>
								</div>
							</div>
						</div>
								</div>
							</div>
						</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							</div>
						
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Admintransportdetail;