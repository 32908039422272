import { useState,useEffect ,React} from "react";
import axios from 'axios';

function Admintreebreak()
{
     var [mydata,setmydata]=useState([]);
    

        async function loaddata()
      {
        
       var x=await fetch(process.env.REACT_APP_URL+'Admintreebreak');
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);

    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Tree Break</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30"> Tree Break</h4>
                                {/* <hr width="100%"/> */}
                                

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                                      
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th>Sr No</th>
												    <th>Applicant Id</th>
												    <th>Temp Id</th>
													<th>DS</th>
                                                    <th>Sponsor</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td>{index+1}</td>
                                                    <td>{row.ApplicantId}</td>
                                                    <td>{row.Tempid}</td>
                                                    <td>{row.DS}</td>
                                                    <td>{row.Sponsor}</td>
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Admintreebreak;