import React,{useEffect,useState, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import axios from 'axios';
function Depotpersonaldetail()

{
   
	const imagedefault = "/assets/images/users/avatar-1.jpg";
const [data,setdata]=useReducer(myreducer, initialstate);
const [myimgupload, setmyimgupload] = useReducer(myreducer, initialstate);
const [image, setImage] = useState(null);
const [file, setfile] = useState(null);

const handleFileChange = (event) => {
	const imgfile = event.target.files[0];

	if (imgfile) {


		const reader = new FileReader();
		reader.onloadend = () => {

			setImage(reader.result);
			setfile(imgfile);

		};
		reader.readAsDataURL(imgfile);

	}
};

const onSubmit = async (e) => {

	e.preventDefault();

	if (!file) {

		console.error('No file selected.');
		return;
	}

	const imagename=localStorage.getItem('tempID')+'_pic';
	// Create a new file with the new name
    const newFileName = `${imagename}.${file.name.split('.').pop()}`; // e.g., dsid.jpg
    const newFile = new File([file], newFileName, { type: file.type });

	const formData = new FormData();
  
	formData.append('file', newFile);
	//alert("newFile"+newFile.filename);

	
	try {
		const res = await axios.post(process.env.REACT_APP_URL + 'upload', formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		const { fileName } = res.data;

		console.log('fileName', fileName);

		fetchbypostmethod(setmyimgupload, 'dsuploadphoto', { fileName: fileName, applicantid: localStorage.getItem('applicantID') });


	} catch (err) {
		console.error('ret', err);
	}
};
async function loaddata()
{

fetchbygetmethod(setdata, 'personaldetails', { tempid: localStorage.getItem("tempID") });
}	

useEffect(()=>{loaddata()},[]);
useEffect(() => {
	// if (isFirstRender.current) {
	// 	isFirstRender.current = false;
	//   } else {
	//console.log("mydata",myimgupload);
	if (myimgupload.myerror == true)
		alert("Please Upload Your Image Again!!");
	else if (myimgupload.initialstage==false && myimgupload.myloading == false)
	{
		alert('File uploaded Successfully');
		loaddata();
		
	}
		
	// }

}, [myimgupload]);


    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Personal Details</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Personal Details</h4>
										<hr width="100%" />
										 {data.myloading == true ? <Spinner />
											: data.myerror == true ? <Nodatafound />
												:
												data.mypost.length > 0 &&
												data.mypost.map((mydata,index)=>{
                           return(
										<div class="row">
										<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Personal Details</button>
															</div>
														</div>
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>Applicant Name  </label>
															 <label class="form-control">
															 {mydata.TempID}
															 </label>
																 <label  class="form-control">
																 {mydata.ApplicantName}  
																 </label>
																 
														</div>
														<div class="form-group">
															<label>Applicant DOB</label>
															
																<label class="form-control">
																	 {mydata.ApplicantDob}
																</label>
															
															
														</div>
														<div class="form-group">
															<label>Applicant Gender</label>
															<label class="form-control">
																	 {mydata.ApplicantGender=='M'?'Male':'Female'}
																</label>
														</div>

														<div class="form-group">
															<label>Nominee DOB</label>
															<label class="form-control">
																	{mydata.NomineeDob}
																</label>
														</div>

														<div class="form-group">
															<label>Applicant Photo</label>
															<div className="fallback">
															<input name="file" type="file" onChange={(e)=>handleFileChange(e)}></input>
														</div>
														</div>

														<div class="form-group">
															<label>Date</label>
															<label class="form-control">
																	 {mydata.Date}
																</label>
														</div>
														   
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Applicant Type</label>
															<div>
																<label class="form-control">
																	{mydata.ApplicantType}
																</label>
															</div>
														</div>
														<div class="form-group">
															<label>Applicant Guardion Name</label>
															<div>
															<label class="form-control">
																{mydata.ApplicantGuardionName}	
																</label>
																
																	
																
															</div>
														</div>
														<div class="form-group">
															<label>Nominee Name</label>
															<div>
																<label class="form-control">
																	{mydata.NomineeName}
																</label>
																	
																
															</div>
														</div>
														
                                       <div class="form-group">
                                           <label>Password</label>
                                           <label class="form-control">
                                                   {mydata.Password}
                                               </label>
                                       </div>
									   <div class="form-group">
															<label>Applicant Photo</label>
															<div>
																<img src={image ? image : Array.isArray(mydata.mypost) && mydata.mypost.length > 0 && mydata.mypost[0].ApplicantPhoto ? './uploads/' + mydata.mypost[0].ApplicantPhoto : process.env.PUBLIC_URL + imagedefault} alt="Applicant Photo" class="form-control" style={{ height: 66,width: 100}}/>
																	
																
															</div>
														</div>
														<div class="form-group">
															
															<div>
															<button type="submit" onClick={(e)=>onSubmit(e)}
																				class="btn btn-primary waves-effect waves-light">
																				Upload Photo</button>
																			
																	
																
															</div>
														</div>
														<div class="form-group">
															<label>IsBlock</label>
													
															
																<select class="form-control">
                                                                    <option>Block</option>
																<option>Unblock</option>
																
															</select>
															
															
														</div> 
														
														</form>
														
														
														
														</div>
														
														</div>
														
													
												</div>
												
											 )
												})
										} 
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <Gotohome mydata={data} /> */}
				</div>
    )

}

export default Depotpersonaldetail;