import { Link} from 'react-router-dom'
import { useState, useEffect,React } from 'react';
function AdminLeftmenu(props)
{
    //const [value,setvalue]=useState(0);

    
    const [mydata,setdata]=useState([]);
    const imagefromdb="https://jmvdnet.jmvdbusiness.com/DirectSeller/DSImages/";
    const imagedefault="/assets/images/users/avatar-1.jpg";
    let z;
    async function loaddata()
    {
        var x = await fetch(process.env.REACT_APP_URL+"profileinformation?tempid="+localStorage.getItem("tempID"));
        console.log("id: "+localStorage.getItem("tempID"));
        console.log("URL: "+x);
        var y = await x.json();
        z = await y.recordset;
        console.log('dataprofileleft',z );
        setdata(z);
        console.log('dataprofileinfoleft',mydata );
        //return z;
        // if (mydata.length>0){
        //     setvalue();
        // } else {
        //     setvalue();
        // }
       // console.log("img"+value);
    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));
    
        
    }
    
    useEffect(()=>{
      
        loaddata();
        
           
    },[])
    

    
    return(<div className="left side-menu">
    <div className="sidebar-inner slimscrollleft">
        <div className="user-details">
            <div className="text-center">
                <img src={mydata.length>0 ? imagefromdb+mydata[0].ApplicantPhoto:process.env.PUBLIC_URL+imagedefault} alt=""
                    className="img-circle"/>
            </div>
            <div className="user-info">
                <div className="dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown"
                        aria-expanded="false">{mydata.length>0 && mydata[0].applicantname}</a>
                    <ul className="dropdown-menu">
                        <li><a href="javascript:void(0)"> Profile</a></li>
                        <li><a href="javascript:void(0)"> Settings</a></li>
                        <li><a href="javascript:void(0)"> Lock screen</a></li>
                        <li className="divider"></li>
                        <li><a href="javascript:void(0)"> Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="sidebar-menu">
            
            <ul >
               
                <li className="has_sub" onClick={()=>{ var a=document.getElementById('UserManagement');
                            a.style.display=a.style.display=='none'?'block':'none';
                            console.log("working");}}><a href="javascript:void(0);"
                    className="waves-effect">
                        <i className="mdi mdi-album"></i> 
                        <span>User Management</span> 
                        <span className="pull-right">
                        <i className="mdi mdi-plus"></i></span></a>
                           
                <ul id='UserManagement' className="list-unstyled">
                         <li><Link to='Admincreateuser' >Create User</Link></li>
                        <li><Link to='Adminchangepassword' >Change Password</Link></li>
                         <li><Link to='Adminchangepermission' >Change Permission</Link></li>
                        <li><Link to='Adminupdateuserlogin' >Update User Logins</Link></li>
                        <li><Link to='#'>Add/Edit Stock</Link></li>
                        <li><Link to='#' >Edit Invoice</Link></li>
                        <li><Link to='Adminprogramapprove' >Program Approve</Link></li>
                        <li><Link to='Adminpanelapprove' >Panel Approve</Link></li>
                        <li><Link to='Adminpaymentdetails' >Payment Detail</Link></li>
                        <li><Link to='Admintransportdetail' >Edit Tranport Detail</Link></li>
                        
                    </ul>
                  
                    </li>
                    <li className="has_sub" onClick={()=>{var a=document.getElementById('InventoryMaster');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Inventory Master </span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='InventoryMaster' className="list-unstyled">
                            <li><Link to='Itemcategory'>Item Category</Link></li>
                            <li><Link to='Itemsubcategory'>Item SubCategory</Link></li>
							<li><Link to='Unitmaster'>Unit Master</Link></li>
                            <li><Link to='Itemmaster'>Item Master</Link></li>
                            
                        </ul></li>
                    

                    <li className="has_sub" onClick={()=>{var a=document.getElementById('Commission/Tax Master');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Commission/Tax Master </span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='Commission/Tax Master' className="list-unstyled">
                            <li><Link to='Admincomissionmaster'>Commission Master</Link></li>
                            <li><Link to='Admintaxmaster'>Tax Master</Link></li>
                            <li><Link to='Adminitemdetails'>Item detail</Link></li>
                            <li><Link to='Admingenerateinvoicecode'>Generate Invoice Code</Link></li>
                            <li><Link to='Admincreditpaymentmaster'>Credit Payment Master</Link></li>
							
                            
                        </ul></li>


                         <li className="has_sub" onClick={()=>{var a=document.getElementById('OrderMaster');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Order Master </span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='OrderMaster' className="list-unstyled">
                            <li><Link to='Companymaster'>Company Master</Link></li>
                            <li><Link to='Vendormaster'>Vendor Master</Link></li>
                            <li><Link to='POmaster'>PO Master</Link></li>
                            {/* <li><Link to='Vieworder'>View Order</Link></li> */}
							
                            
                        </ul></li>

                         <li className="has_sub" onClick={()=>{var a=document.getElementById('InventoryTransaction');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Inventory Transaction</span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='InventoryTransaction' className="list-unstyled">
                            <li><Link to='Adminpurchasevoucher'>Purchase Voucher</Link></li>
                            <li><Link to='Adminsalevoucher'>Sale Voucher</Link></li>
                            <li><Link to='#'>Company Sale</Link></li>
                            <li><Link to='Admindamageitem'>Damaged Stock</Link></li>
							
                            
                        </ul></li>

                          <li className="has_sub" onClick={()=>{var a=document.getElementById('Inventory/KycReports');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Inventory/Kyc Reports</span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='Inventory/KycReports' className="list-unstyled">
                            <li><Link to='Adminlistofarea'>List(No Area/City)</Link></li>
                            <li><Link to='Adminstock'>Stock</Link></li>
                            <li><Link to='AdminCompanysalepurchase'>Company Sale/Purchase</Link></li>
                            <li><Link to='Adminbranchdepotsale'>Branch/Depot Sale</Link></li>
                             <li><Link to='Adminconsolidatebusiness'>Consolidated Business</Link></li>
                            <li><Link to='Adminbusinessreport'>Business Report</Link></li>
                            <li><Link to='Admindoublebilling'>Double Billing Checking</Link></li>
                            <li><Link to='Admincompanysaletransfer'>Company Sale Transfer</Link></li>
                             <li><Link to='Admintreebreak'>Tree Break</Link></li>
                            <li><Link to='Adminpaymentinfo'>Payment Info</Link></li>
                            <li><Link to='Adminpaymentledger'>Payment Ledger</Link></li>
                            <li><Link to='Adminviewcredit'>View Credit</Link></li>
                             <li><Link to='Adminstockafterturnover'>Stock After Turnover</Link></li>
                            <li><Link to='Adminviewprevious'>View Previous</Link></li>
                             <li><Link to='Admindsmonthlypurchasing'>DS Monthly Purchasing</Link></li>
                             <li><Link to='AdminDsApprovedKycList'>Ds Approved Kyc List</Link></li>
                            <li><Link to='AdminDspendingKycList'>Ds Pending Kyc List</Link></li>
                             <li><Link to='AdminDsRejectKycList'>Ds Reject Kyc List</Link></li>
                             <li><Link to='AdminMonthlyDSJoining'>Ds Monthly Joining</Link></li>
                            <li><Link to='Adminstoremonthlyselling'>Store Monthly Selling</Link></li>
                            <li><Link to='Adminstoremonthlyreturn'>Store Monthly Return</Link></li>
                            <li><Link to='#'>Store Monthly Statement</Link></li>
                            <li><Link to='AdminStoreDownLinePurchasing'>Store  DownLine Purchasing</Link></li>
                             <li><Link to='Admindownline'>Downline</Link></li>
                            <li><Link to='AdminTDSreport'>TDS Report</Link></li>
                             <li><Link to='AdminStatewiseDsPurchasing'>Statewise Ds Purchasing</Link></li>
                            <li><Link to='AdminPowerLine'>Power Line</Link></li>
                            <li><Link to='AdminApowerline'>Admin Power Line</Link></li>
                            <li><Link to='AdminDeductStockReport'>Deduct Stock Report</Link></li>
                            <li><Link to='Adminpaymenttransferreport'>Payment Transfer Report</Link></li>
                             <li><Link to='AdminMonthwisePurchasingReport'>Monthwise Purchasing Report</Link></li>
                            <li><Link to='AdminBDSStockReport'>BDS Stock Report</Link></li>
                            <li><Link to='Admincompanysalebd'>Company B/D Sale with BV</Link></li>
                            <li><Link to='AdminBusinessBuildingClub'>Business Building Club</Link></li>
                            <li><Link to='AdminDownlineRepurchaseIncome'>Downline Repurchase Income</Link></li>
                             <li><Link to='AdminOpenChoiceBillReport'>Open Choice Bill Report</Link></li>
                            <li><Link to='AdminDreamclubBillReport'>Dreamclub Bill Report</Link></li>
                           
                            
                            
							
                            
                        </ul></li>

                        <li className="has_sub" onClick={()=>{var a=document.getElementById('FinanceMaster');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Finance Master</span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='FinanceMaster' className="list-unstyled">
                            <li><Link to='#'>Account Group</Link></li>
                            <li><Link to='#'>Sub Account Group </Link></li>
                            <li><Link to='#'>General Ledgers</Link></li>
                            <li><Link to='#'>Sub Ledgers</Link></li>
							
                            
                        </ul></li>

                        <li className="has_sub" onClick={()=>{var a=document.getElementById('FinanceTransaction');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Finance Transaction</span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='FinanceTransaction' className="list-unstyled">
                            <li><Link to='#'>Voucher</Link></li>
                            <li><Link to='#'>Sub Voucher </Link></li>
                            <li><Link to='#'>Voucher History</Link></li>
                            <li><Link to='#'>Sub Voucher History</Link></li>
							
                            
                        </ul></li>

                          <li className="has_sub" onClick={()=>{var a=document.getElementById('DirectSellerPayout');
                            a.style.display=a.style.display=='none'?'block':'none';}}><a href="javascript:void(0);"
                        className="waves-effect"><i className="mdi mdi-album"></i> 
                        <span>Direct Seller Payout</span> 
                        <span className="pull-right"><i className="mdi mdi-plus">
                        </i></span></a>
                    <ul id='DirectSellerPayout' className="list-unstyled">
                            <li><Link to='Adminsearchawareness'>Search Awareness</Link></li>
                            <li><Link to='Directseller'>Direct Seller</Link></li>
                            <li><Link to='DSpersonaldetails'>Direct Seller Profile</Link></li>
                            <li><Link to='yourgroup'>Direct Seller Tree</Link></li>
                            <li><Link to='AdminDsBusinessVolume'>DS Business Volume</Link></li>
                             <li><Link to='AdminDsmonthlystatement'>Monthly Statement</Link></li>
                            <li><Link to='AdminRepurchaseandFreeItems'>Repurchase Free</Link></li>
                            <li><Link to='AdminGeneratePayout'>Generate Payout</Link></li>
                            <li><Link to='AdminViewCommision'>View Commission</Link></li>
                            <li><Link to='AdminViewRoyality'>View Royality</Link></li>
                            <li><Link to='AdminDSUncompleteProfile'>DS Uncomplete Profile</Link></li>
							
                            
                        </ul></li>


  
                       {/* <li><Link  class="waves-effect"><i
									class="mdi mdi-home"></i><span> Your KYC <span
										class="badge badge-primary pull-right">1</span></span></Link></li> */}
								{/* <li><Link to='Storepaymentdetail' class="waves-effect"><i
											class="mdi mdi-home"></i><span> Payment Clearance <span
												class="badge badge-primary pull-right">1</span></span></Link></li>
								<li><a href="index.html" class="waves-effect"><i
													class="mdi mdi-home"></i><span> Product Order Form  <span
														class="badge badge-primary pull-right">1</span></span></a></li>	 */}

                           
            </ul>
        </div>
        <div className="clearfix"></div>
    </div>
</div>)
}

export default AdminLeftmenu;