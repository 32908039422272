import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbypostmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function Storepurchaseapprove() {

	var [mydata, setmydata] = useReducer(myreducer, initialstate);
	 var [updateddata,setupdateddata]=useReducer(myreducer, initialstate);

	async function updatedata(financialyear, invoiceno, isdelivered) {
		
		//loaddata();
		console.log("mydata", mydata);
		fetchbypostmethod(setupdateddata, 'checkdelivered', {
			applicantid: localStorage.getItem("applicantID"),
			financialyear:financialyear,
			invoiceno:invoiceno,
			isdelivered:isdelivered
		});
	}



	async function loaddata() {
		
		fetchbygetmethod(setmydata, 'purchaseapprove', { applicantid:localStorage.getItem("applicantID")});
	}



	useEffect(() => { loaddata(); }, []);
	useEffect(() => { if(updateddata.initialstage!=true){console.log("updateddata",updateddata);loaddata();} }, [updateddata]);



	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Purchase Approve</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Purchase Approve</h4>
										<hr width="100%" />

										<div class="row">
											<div class="form-group">
												<div>
													<button type="submit"
														class="btn btn-primary waves-effect waves-light">
														Purchase Approve</button>
												</div>
											</div>
											

											{/* <div class="container">
												<div class="row justify-content-center mt-5">
													<div class="col-md-6">
														<form>
															<div class="input-group col-lg-12 ">
																<input type="text" class="form-control " placeholder="Search" aria-label="Search" />

															</div>
														</form>
													</div>
												</div>
											</div> */}

<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
													
													<th>Invoice No</th>
													<th>Date</th>
													<th>Applicant Type</th>
													<th>Applicant Name</th>
													<th>Panel Name</th>
													<th>Mobile No</th>
													<th>Is Delivered</th>
													<th>Action</th>


												</tr>
											</thead>
											<tbody>

												{mydata.myloading == true ? <Spinner />
													: mydata.myerror == true ? <Nodatafound />
														:Array.isArray(mydata.mypost) && mydata.mypost.length>0 &&
														mydata.mypost.map((row, index) => {
														return (
															<tr>
																
																<td>{row.invoiceno}</td>
																<td style={{ width: 90 }}>{row.invoicedate?row.invoicedate.split('T')[0]:''}</td>
																<td>{row.applicanttype}</td>
																<td>{row.applicantname}</td>
																<td>{row.name}</td>
																<td>{row.mobileno}</td>
																<td>{row.IsDelivered}</td>
																<td><button type="submit" onClick={()=>{updatedata(row.financialyear,row.invoiceno,row.IsDelivered=='Y'?'N':'Y')}}
														class="btn btn-primary waves-effect waves-light">
														Update</button></td>

															</tr>
														);
													})}

											</tbody>

										</table>

										</div>




									</div>

								</div>
							</div>
						</div>



					</div>
				</div>
			</div>

			
		</div>
	)



}

export default Storepurchaseapprove;