import React,{useState,useEffect} from 'react'
function Adminchangepermission()
{

var [mydata,setmydata]=useState([]);
var [isEditing,setisEditing]=useState(false);
var [AssignUser,setAssignUser]=useState([]);



  async function AdminAssignUser()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Admiassignuser');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setAssignUser(a);
    console.log("AssignUser",AssignUser);
  }
 
 

async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Adminchangepermission');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
function Editclick(){
	 
	 var mydata=document.getElementById("Displaynone");
	mydata.style.display='none'
   var data=document.getElementById("Display");
	data.style.display='block'
}
  
  useEffect(()=>{AdminAssignUser();loaddata();},[]);

  return(
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Change Permission</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Change Permission</h4>
										<hr width="100%" />
										
										<div class="row">
                                        	<div class="form-group">
															<div>
																<button type="submit"
																	class="btn btn-primary waves-effect waves-light">
																	Change Permission</button>
															</div>
														</div> 
                                                       
                                                        	
                                                          
													
												</div>

       
										
										
											</div>
                                            
										</div>
									</div>
								</div>
							
							<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Change Permission</h4>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th></th>
													<th>Form Id</th>
													<th>Id</th>
													<th>User Name</th>
													<th>User Id</th>
													<th>Form Name</th>
													<th>Description</th>
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row1,index)=>{
                                              
                                              return(	
											
                                              <tr>
													<td>{index+1}</td>
													<td><button id='Displaynone'  onClick={Editclick}>Edit</button>
													<div id='Display'  style={{display:'none'}}>
														<button>UnAssingn</button>
														<button>Cancel</button>
													</div> </td>
                                                    <td>{row1.FormId}</td>
													<td>{row1.UID}</td>
                                                    <td>{row1.Username}</td>
													<td>{row1.Userid}</td>
													<td>{row1.FormName}</td>
													<td>{row1.Description}</td>
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
										
									</div>
								</div>
							</div>
						</div>
							
							<div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-b-30 m-t-0">Assign User New Permission</h4>
                                          
											<div class="form-group">
															<label>Enter User Id</label>
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search" aria-label="Search"  />
															</div>
															</div>
														</div>
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    
												    <th>Sr No.</th>
													<th>Form Id</th>
													<th></th>
													<th>Form Name</th>
													<th>Description</th>
													
													
												</tr>
											</thead>
											<tbody>
												{AssignUser.length>0 &&
											  AssignUser.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													<td>{index+1}</td>
													 <td>{row.FormId}</td> 
                                                     <td><input type="checkbox"/></td> 
													 <td>{row.FormName}</td> 
                                                     <td>{row.Description}</td>
													
									
												</tr>
											
                                              ) 
                                              })
												
                                                } 
											</tbody>
										
										</table>
										  <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:750}}>
                                                            Submit</button>
                                                       
                                                    </div>
                                                </div>
									</div>
								</div>
							</div>
						</div>
							
							</div>
						</div>
					</div>


				</div>
	)


  
}

export default Adminchangepermission;