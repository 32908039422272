import { useState, useEffect, React, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import {Gotohome} from '../Commonfiles/checkfortoken';
function DscommunicationDetails() {
	const [mydata, setmydata] = useReducer(myreducer, initialstate);

	async function loaddata() {

		fetchbygetmethod(setmydata, 'communicationdetail', { tempid: localStorage.getItem("tempID") });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
	}

	useEffect(() => { loaddata() }, [])

	return (
		<div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Update Profile</h4>

					</div>
				</div>

				<div class="page-content-wrapper ">
					<div class="container">
						{mydata.initialstage==true?<></>: mydata.myloading == true ? <Spinner />
							: mydata.myerror == true ? <Nodatafound />
								:
								mydata.mypost.length > 0 &&
								mydata.mypost.map((row, index) => {
									return (
										<div class="row">
											<div class="col-sm-12">
												<div class="panel panel-primary">
													<div class="panel-body">
														<h4 class="m-t-0 m-b-30">Communication Details</h4>
														<hr width="100%" />
														<div class="row">
															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form class="" action="#">
																		<div class="form-group">
																			<label>Country</label>
																			<label
																				class="form-control"
																			>{row.CountryName}</label>
																		</div>
																		<div class="form-group">
																			<label>City</label>

																			<label class="form-control">{row.Cityname}</label>


																		</div>
																		<div class="form-group">
																			<label>House No.</label>
																			<label class="form-control">{row.HouseNo}</label>
																		</div>
																		<div class="form-group">
																			<label>Mobile No.</label>
																			<label class="form-control">{row.MobileNo}</label>
																		</div>
																		<div class="form-group">
																			<label>Address Proof Type</label>
																			<label class="form-control">{row.AddPType}</label>
																		</div>
																		<div class="form-group">
																			<label>Address Proof </label>
																			<label class="form-control">{row.AddressProof}</label>
																			<div>
																				<input type="email" class="form-control" required
																					parsley-type="email" placeholder="Enter a valid e-mail" />
																			</div>
																		</div>





																	</form>
																</div>
															</div>
															<div class="col-sm-6 col-xs-12">

																<div class="m-t-20">
																	<form action="#">
																		<div class="form-group">
																			<label>State</label>
																			<label class="form-control">{row.StateName}</label>
																		</div>
																		<div class="form-group">
																			<label>Area</label>
																			<label class="form-control">{row.areaname}</label>
																		</div>
																		<div class="form-group">
																			<label>Email</label>
																			<label class="form-control">{row.Email}</label>
																		</div>


																		<div class="form-group">
																			<label>Phone No. Phone no with STD code.</label>
																			<label class="form-control">{row.PhoneNo}</label>
																		</div>
																		<div class="form-group">
																			<label>ID Proof Type</label>
																			<div>
																				<input type="text" class="form-control" required
																					data-parsley-min="6" placeholder="Min value is 6" />
																			</div>
																		</div>
																		<div class="form-group">
																			<label>ID Proof</label>
																			<div>
																				<input type="text" class="form-control" required
																					data-parsley-min="6" placeholder="Min value is 6" />
																			</div>
																		</div>
																	</form>




																</div>

															</div>


														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
						}
					</div>
				</div>
			</div>
			<Gotohome mydata={mydata}/>
		</div>
	)
}

export default DscommunicationDetails;