
import {useState,useEffect,React} from 'react'
function Admingenerateinvoicecode()
{ let personaldata=[{ApplicantName:"vaishali",GuardianName:"xyz",
                     ApplicantDob:'12/12/2002',NomineeName:'ghy',
					 Nomineedob:'10/9/2045',gender:'Female' }]

	const [data,setdata]=useState([]);

	function loaddata()
	{
		setdata(personaldata);
	}	

	useEffect(()=>{loaddata()})

    return(
        <div class="content-page">
			<div class="content">
				<div class="">
					<div class="page-header-title">
						<h4 class="page-title">Payment Info Record</h4>
						
					</div>
				</div>
				
				<div class="page-content-wrapper ">
					<div class="container">
						<div class="row">
							<div class="col-sm-12">
								<div class="panel panel-primary">
									<div class="panel-body">
										<h4 class="m-t-0 m-b-30">Fill Followings Details</h4>
										<hr width="100%" />
										{/* { data.length>0 &&
										  data.map((mydata,index)=>{
											return( */}
										<div class="row">
											<div class="col-sm-6 col-xs-12">
											
												<div class="m-t-20">
													<form class="" action="#">
														<div class="form-group">
															<label>ID </label>
															<div>
																<input type="text" class="form-control" required
																	parsley-type="text"  />
															</div>
																 
														</div>
														<div class="form-group">
															<label>Payment Date</label>
															
															<div>
																<input type="text" class="form-control" required
																	parsley-type="text"  />
															</div>
															
															
														</div>
														<div class="form-group">
															<label>Payment Mode</label>
															 <select class="form-control">
															    <option>NIFT/RTGS</option>
                                                                <option>CHEQUE</option>
                                                                <option>CASH</option>
                                                                <option>CREDIT</option>
                                                                
																</select>
														</div>
														
														
														
													
														
														
													</form>
												</div>
											</div>
											<div class="col-sm-6 col-xs-12">
												
												<div class="m-t-20">
													<form action="#">
														<div class="form-group">
															<label>Bank Details</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text"  />
															</div>
															</div>
														</div>
														<div class="form-group">
															<label>Payment Detail</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text"  />
															</div>
															</div>
														</div>
														<div class="form-group">
															<label>Payment Amount</label>
															<div>
																<div>
																<input type="text" class="form-control" required
																	parsley-type="text"  />
															</div>
															</div>
														</div>
														
														</form>
														
														
														
														</div>
														
														</div>
													 <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:800,marginTop:5}}>
                                                            Add</button>
                                                       
                                                    </div>
                                                </div>	
													
												</div>
											{/* )
												})
										} */}
											</div>
										</div>
									</div>
								</div>
                                 <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Bank Details</th>
													<th>Payment Date</th>
													<th>Payment Detail</th>
													<th>Payment Mode</th>
                                                    <th>Against Credit</th>
                                                    <th>Payment Amount</th>
                                                   
													
													
													
												</tr>
											</thead>
											<tbody>
												{/* {mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	 */}
											
                                              <tr>
													
												
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    
                                                   
													
									
												</tr>
{/* 											
                                             ) 
                                              })
												
                                                } */}
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Admingenerateinvoicecode;