import { useState,useEffect ,React} from "react";
import axios from 'axios';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminAddEditCompany()
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    let z;
    async   function loaddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"pd?id=2082");
        var y=await x.json();
          z=await y.recordset;
          console.log('data10',z );
          setdata(z);
         console.log('data2',mydata );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    
    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Company</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add /Edit Company</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Company Data</button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Company Name</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Company Type</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Contact number</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Email</label>
                                                   <input type="email" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Address</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>TIN No:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>CST No:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>IFSC Code:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Account No:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Bank Name:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Branch Name:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                   
                                    
                                   <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>State</label>
                                                    <select class="form-control">
															    <option selected="selected" value="8">Uttar Pradesh</option>
                                                               <option value="9">Haryana</option>
                                                               <option value="10">Punjab</option>
                                                               <option value="18">Maharashtra</option>
                                                                <option value="19">Bihar</option>
                                                               <option value="20">Madhya Pradesh</option>
                                                              <option value="21">Odisha</option>
                                                               <option value="23">Assam</option>
                                                              <option value="24">Goa</option>
                                                               <option value="25">Delhi</option>
                                                              <option value="26">Rajasthan</option>
                                                               <option value="30">Gujarat</option>
                                                               <option value="5">Himachal Pradesh</option>
                                                               <option value="32">Andhra Pradesh</option>
                                                               <option value="33">Arunachal Pradesh</option>
                                                               <option value="34">Chhattisgarh</option>
                                                               <option value="35">Jammu and Kashmir</option>
                                                               <option value="36">Jharkhand</option>
                                                               <option value="37">Karnataka</option>
                                                               <option value="38">Kerala</option>
                                                               <option value="39">Manipur</option>
                                                               <option value="40">Meghalaya</option>
                                                               <option value="41">Mizoram</option>
                                                               <option value="42">Nagaland</option>
                                                               <option value="43">Sikkim</option>
                                                               <option value="44">Tamil Nadu</option>
                                                               <option value="45">Telangana</option>
                                                               <option value="46">Tripura</option>
                                                               <option value="47">Uttarakhand</option>
                                                               <option value="48">West Bengal</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>City</label>
                                                    <select class="form-control">
													    <option>Block</option>
                                                        <option>Unblock</option>
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Area</label>
                                                    <select class="form-control">
													    <option>Block</option>
                                                        <option>Unblock</option>
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Pincode:</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Is Block</label>
                                                    <select class="form-control">
													    <option>Block</option>
                                                        <option>Unblock</option>
																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:832}}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminAddEditCompany;