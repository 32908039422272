import { useState,useEffect ,React} from "react";
import axios from 'axios';

function Adminitemdetails()
{
   var [mydata,setmydata]=useState([]);


	async function loaddata()
  {
   var x=await fetch(process.env.REACT_APP_URL+'Adminitemdetails');
   
   var y=await x.json();
   var z=JSON.stringify(y.recordset);
   var a=JSON.parse(z);
   setmydata(a);
    console.log("mydata",mydata);
  }
 
  useEffect(()=>{loaddata();},[]);


    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Item Detail</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Item Detail</h4>
                                <hr width="100%"/>
                                <div className="row">
                                <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Panel Name</label>
                                                    <select class="form-control">
															    <option>Branch</option>
                                                                <option>Depot</option>
                                                                <option>Store</option>
                                                                <option>Admin</option>
																 

																</select>
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                    <div className="form-group">
                                                    <label>Item Name</label>
                                                    <select class="form-control">
															    <option>Branch</option>
                                                                <option>Depot</option>
                                                                <option>Store</option>
                                                                <option>Admin</option>
																 

																</select>
                                                </div>
                                                   
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="row">
                                
                                    <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>Business Volume</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Cost Price</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>SGST</label>
                            
                                                    <select class="form-control">
															    <option>Select</option>
                                                                <option>2.50</option>
                                                                <option>6.50</option>
                                                                <option>9.00</option>
                                                                <option>14.00</option>
                                                                <option>0.00</option>
																 

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Weight</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>HSN Code</label>
                                                        <input type="text" className="form-control" value={0}/>
                                                    </div>
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                     <div className="form-group">
                                                        <label>MRP</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Selling Price</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>CGST</label>
                            
                                                    <select class="form-control">
															    <option>Select</option>
                                                                <option>2.50</option>
                                                                <option>6.50</option>
                                                                <option>9.00</option>
                                                                <option>14.00</option>
                                                                <option>0.00</option>
																 

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Panel Commission</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Final Sale Rate</label>
                                                        <input type="text" className="form-control" value={0}/>
                                                    </div>
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                                    <div className="form-group">
                                                        <label>Standard Price</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                 <div className="form-group">
                                                        <label>Purchase  Price</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>IGST</label>
                            
                                                    <select class="form-control">
															    <option>Select</option>
                                                                <option>5.00</option>
                                                                <option>12.00</option>
                                                                <option>18.00</option>
                                                                <option>28.00</option>
                                                                <option>0.00</option>
																 

																</select>
                                                    </div>
                                                     <div className="form-group">
                                                        <label>Batch No.</label>
                                                        <input type="number" className="form-control" value={0}/>
                                                    </div>
                                                    
                                                   
                                                    
                                                    
                                                </form>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:220,marginTop:80}}>
                                                            Submit</button>
                                                       
                                                    </div>
                                                </div>

                                </div>



                                <div class="row" >
							<div class="col-xxxl-12">
								<div class="panel panel-primary">
									<div class="panel-body table-container">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons" 
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                <th></th>
												    <th>Sr No.</th>
												    <th>Panel Type</th>
													<th>ID</th>
													<th>Item Name</th>
													<th>Commision</th>
                                                    <th>Purchasing Price</th>
                                                    <th>Selling Price</th>
                                                    <th>Standard Price</th>
                                                    <th>MRP</th>
                                                    <th>BV</th>
                                                    <th>SGST</th>
                                                    <th>CGST</th>
                                                    <th>IGST</th>
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													<td><input type="button" value="Edit" /> </td>
                                                    <td>{index+1}</td>
                                                    <td>{row.PanelType}</td>
                                                    <td>{row.ItemDetailId}</td>
                                                    <td>{row.ItemName}</td>
                                                    <td>{row.panelcommision}</td>
                                                    <td>{row.purchasingprice}</td>
                                                    <td>{row.sellingprice}</td>
                                                    <td>{row.standardprice}</td>
                                                    <td>{row.mrp}</td>
                                                    <td >{row.businessvolume}</td>
                                                    <td>{row.SGST}</td>
                                                    <td>{row.CGST}</td>
                                                    <td>{row.IGST}</td>
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
        
</div>)
}

export default Adminitemdetails;