import { Float } from "react";
import { Link } from 'react-router-dom'
import { FiShoppingBag } from "react-icons/fi";
import { BsBagFill } from "react-icons/bs";
import { BsCashCoin } from "react-icons/bs";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { FaRupeeSign } from "react-icons/fa";
import React, { useState, useEffect, useReducer } from 'react'
import { initialstate, myreducer, fetchbygetmethod, fetchbyputmethod } from '../Commonfiles/Myreducer';
import Spinner from '../Commonfiles/spinner';
import Nodatafound from '../Commonfiles/nodatafound';
import { Gotohome } from '../Commonfiles/checkfortoken';
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
//import {bardata} from "./data.js";
//import { addComponentMeta as Utils } from 'js-react-utils';
//import { UtilityFunction as Utils } from 'js-react-utils';

export default function DSdashboard() {



	var [monthlypurchase, setmonthlypurchase] = useReducer(myreducer, initialstate);
	var [lastpurchase, setlastpurchase] = useReducer(myreducer, initialstate);
	const [mydata, setmydata] = useReducer(myreducer, initialstate);
	const [mykyc, setkyc] = useReducer(myreducer, initialstate);

	async function loaddatakyc() {
        try{
		fetchbygetmethod(setkyc, 'personaldetails', { tempid: localStorage.getItem("tempID") });
		}
		catch (error) {
			console.error("Error fetching data:", error);
		}
	}




	async function loadbarchart() {
		try {

			fetchbygetmethod(setmydata, 'financialyearearning', { dsid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	async function loaddata() {
		try {

			fetchbygetmethod(setmonthlypurchase, 'currentmonthpurchase', { applicantid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	async function loaddata2() {
		try {

			fetchbygetmethod(setlastpurchase, 'lastpurchase', { applicantid: localStorage.getItem("applicantID"), financialyear: process.env.REACT_APP_FINANCIAL_YEAR });//,old:old.value,nyu:nyu.value});//, month: month, year: year })
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}


	useEffect(() => { loaddata(); loaddata2(); loadbarchart(); loaddatakyc(); }, [])
	useEffect(() => {
		console.log("lastpurchase", lastpurchase);
		console.log("monthlypurchase", monthlypurchase);
		console.log("mydata", mydata);
		console.log("mykyc:", mykyc);
	}, // Log the mykyc data as well
		[lastpurchase, monthlypurchase, mydata, mykyc])

	return (<div class="content-page">
		<div class="content">
			<div class="">
				<div class="page-header-title">
					<h4 class="page-title">Dashboard</h4>
				</div>
			</div>
			<div class="page-content-wrapper ">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-lg-3" >
							<div class="panel text-center" style={{ height: 134 }}>
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Total
										Monthly Purchases</h4>
								</div>
								<div class="panel-body p-t-10">
									{/* <FiShoppingBag style={{marginLeft:10}} /> */}
									<h2 class="m-t-0 m-b-15">

										<BsBagFill style={{ marginRight: 13, color: 'sandybrown' }} />
										{/* <RiMoneyRupeeCircleLine /> */}
										<FaRupeeSign style={{ height: 21 }} />

										{mydata.initialstage == true ? <></> : monthlypurchase.myloading == true ? <Spinner />
											: monthlypurchase.myerror == true ? <Nodatafound />
												:
												Array.isArray(monthlypurchase.mypost) && monthlypurchase.mypost.length > 0 &&
												monthlypurchase.mypost.map((row, index) => {
													return (
														<label key={index} >
															{row.totalpurchase}
														</label>
													);
												})
										}
										{/* {Array.isArray(monthlypurchase.mypost) && monthlypurchase.mypost.length > 0 &&
												<label> {monthlypurchase.mypost.reduce((total, row) => total + row.totalpurchase, 0)}</label>
											} */}

									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
											{lastpurchase.length > 0 && 
												 lastpurchase.map((row, index) => {
												  return (
												<label>Your last purchase was made on {row.LastPurchaseDate} </label> 
											);
										})
									  }                                    
											</p> */}
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-lg-3" >
							<div class="panel text-center" style={{ height: 134 }}>
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Total BV
									</h4>
								</div>
								<div class="panel-body p-t-10">
									<h2 class="m-t-0 m-b-15">
										<i
											class="mdi mdi-arrow-up-bold-circle-outline text-success m-r-10"></i>
										<label>

											{monthlypurchase.myloading == true ? <Spinner />
												: monthlypurchase.myerror == true ? <Nodatafound />
													:
													Array.isArray(monthlypurchase.mypost) && monthlypurchase.mypost.length > 0 &&
													monthlypurchase.mypost.map((row, index) => {
														return (
															<label key={index} >
																{row.TotalBV}
															</label>
														);
													})
											}
											{/* { Array.isArray(monthlypurchase.mypost)&& monthlypurchase.mypost.length > 0 &&
													<label>{Math.round(Math.abs(monthlypurchase.mypost.reduce((total, row) => total + row.TotalBV, 0)))}</label>
												} */}



										</label>
									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
												<b>42%</b> Orders in Last 10 months
											</p> */}
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-3" >
							<div class="panel text-center" style={{ height: 134 }}>
								<div class="panel-heading">
									<h4 class="panel-title text-muted font-light">Last
										Earnings</h4>
								</div>
								<div class="panel-body p-t-10">
									<h2 class="m-t-0 m-b-15">
										<BsCashCoin style={{ marginRight: 13, color: 'sandybrown' }} />< b>

											{mydata.initialstage == true ? <></> : mydata.myloading == true ? <Spinner />
												: mydata.myerror == true ? <Nodatafound />
													:
													Array.isArray(mydata.mypost) && mydata.mypost.length > 0 &&

													<label  >
														{mydata.mypost[mydata.mypost.length - 1].NetCommission}
													</label>


											}
										</b>
									</h2>
									{/* <p class="text-muted m-b-0 m-t-20">
												<b>35%</b> From Last 1 Month
											</p> */}
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3" >
							<div className="panel text-center" style={{ height: 134 }}>
								<div className="panel-heading">
									<h4 className="panel-title text-muted font-light">Status</h4>
								</div>
								<div className="panel-body p-t-10">
									<h2 className="m-t-0 m-b-15" style={{ color: 'sandybrown', fontSize: 15, marginTop: -15 }}>
										{mydata.initialstage === true ? null : mydata.myloading === true ? (
											<Spinner />
										) : mydata.myerror === true ? (
											<Nodatafound />
										) : (
											Array.isArray(mykyc.mypost) && mykyc.mypost.length > 0 ? (
												mykyc.mypost[mykyc.mypost.length - 1].IsApproved === 'Y' ? (
													'Your KYC is completed'
												) : (
													'Your KYC is not completed'
												)
											) : (
												'No KYC information available'

											)
										)}
									</h2>
									<h2 className="m-t-0 m-b-15" style={{ color: 'sandybrown', fontSize: 10, marginTop: -16 }}>
										You are in Dream Club.  For details
									</h2>
									<h2 style={{ color: 'sandybrown', fontSize: 10, marginTop: -28 }}><Link to='Coupansdetails' style={{}}><strong>click here</strong></Link></h2>
								</div>
							</div>
						</div>

					</div>
					<div class="row">
						<div class="col-sm-6" >
							<div class="panel panel-primary">
								<div class="panel-body">
									<h4 class="m-t-0">Bar Chart</h4>
									<div>

										<Bar data={{
											labels: Array.isArray(mydata.mypost) && mydata.mypost.map((data) => data.month),

											datasets: [{
												label: 'NetCommision', data: Array.isArray(mydata.mypost) && mydata.mypost.map((row) => row.NetCommission),
												backgroundColor: 'red', borderRadius: 5
											}
											],
											options: {
												scales: {
													y: {
														suggestedMin: 0,
														suggestedMax: 50000
													}
												}
											}


										}} />

									</div>

								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="panel">
								<div class="panel-body">
									<h4 class="m-b-30 m-t-0">Current Offer</h4>

									<p class="font-600 m-b-5">
										नए वर्ष का उपहार
										जनवरी से मार्च तक टोटल 5000,10000,20000,40000 तक के सेलिंग प्राइस की खरीददारी पर पाए निष्चित उपहार।
										<span class="text-primary pull-right"></span>
									</p>
									<hr />
									{/* <div class="progress m-b-20">
												<div class="progress-bar progress-bar-primary "
													role="progressbar" aria-valuenow="80" aria-valuemin="0"
													aria-valuemax="100" ></div>
											</div> */}

									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 5000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="80" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 10000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="50" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 20000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="70" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										टोटल सेलिंग प्राइस 40000 - उपहार
										<span class="text-primary pull-right"></span>
									</p>
									<div class="progress m-b-20">
										<div class="progress-bar progress-bar-primary "
											role="progressbar" aria-valuenow="65" aria-valuemin="0"
											aria-valuemax="100" ></div>
									</div>
									<p class="font-600 m-b-5">
										तीन महीनो में अपने सेलिंग टारगेट को पूरा करे और उपहार में शामिल हो|<span class="text-primary pull-right"></span>
									</p>

								</div>
							</div>
						</div>





					</div>

				</div>
			</div>
		</div>
		{/* <Gotohome mydata={mydata}/> */}
	</div>
	)
}