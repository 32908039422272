import { useState,useEffect ,React} from "react";
import axios from 'axios';

function AdminCompanysalepurchase()
{
    var [mydata,setmydata]=useState([]);
    
   
   // alert(+panelname.value);
    
        async function loaddata()
      {
        
       var x=await fetch(process.env.REACT_APP_URL+'AdminCompanysalepurchase');
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);

function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Purchase Report</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Purchase Report</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:30}}>
                                                            View Purchase Report</button>
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:650}}>
                                                            Print</button>    
                                                        
                                                    </div>
                                                </div> 
                                
                                 
                                    
                                     <div className="form-group">
                                                    <div>
                                                        <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:30}}>
                                                          <input type="checkbox"/> Is With Tax </button>
                                                        <input type="button"
                                                            className="btn btn-default waves-effect waves-light" value="Purchase" style={{marginLeft:5}} onClick={display} />
                                                            
                                                           <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                          <input type="checkbox"/> Purchase Return</button>
                                                        <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                           <input type="checkbox"/> Sale</button>  
                                                            <button type="submit"
                                                            className="btn btn-default waves-effect waves-light" style={{marginLeft:5}}>
                                                           <input type="checkbox"/>Sale Return</button>   
                                                        
                                                    </div>
                                                </div> 
                                    
                                    
                                </div>

                               



                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
                                                    <th></th>
												    <th>Invoice No</th>
												    <th>InvoiceDate</th>
													<th>Name</th>
                                                    <th>ID</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													
                                                    <td><input type="checkbox" /></td>
                                                    <td>{row.InvoiceNo}</td>
                                                    <td>{row.Date}</td>
                                                    <td>{row.VendorName}</td>
                                                    <td>{row.VendorId}</td>
                                                  
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminCompanysalepurchase;