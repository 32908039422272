import { useState,useEffect ,React} from "react";
import axios from 'axios';

// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function AdminItemSubCategory()
{
   var [mydata,setmydata]=useState([]);
    
    
        async function loaddata()
      {
       var x=await fetch(process.env.REACT_APP_URL+'AdminItemsubcategory');
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);



    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Sub Category </h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Add / Edit Sub Category</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                <div className="form-group">
                                                    <div>
                                                        
                                                         
                                                         <button onClick={() => window.history.back()} type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:5}}>
                                                           Sub Category data </button>       
                                                        
                                                    </div>
                                                </div> 
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Sub Category Name</label>
                                                    <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Sub Category Description</label>
                                                   <input type="text" class='form-control'/>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Category</label>
                                                    <select class="form-control">
															   <option value="1">Fmcg Product</option>
															   <option value="2">Healthcare Product</option>
                                                               <option value="3">Home Appliances</option>
                                                               <option value="4">textiles</option>
                                                               <option value="5">Stationary</option>
                                                               <option value="6">Accessories</option>
                                                               <option value="7">ALOEVERA &amp; G-9 GOLD COMBO</option>
                                                               <option value="8">RBO</option>
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Is Block</label>
                                                    <select class="form-control">
															     <option>Block</option>
																<option>Unblock</option>
																

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:832}}>
                                                            Submit</button>    
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                
                               
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}



export default AdminItemSubCategory;