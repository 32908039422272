import { useState,useEffect ,React} from "react";
import axios from 'axios';

function Adminlistofarea()
{
    var [mydata,setmydata]=useState([]);
    
   
   // alert(+panelname.value);
    
        async function loaddata()
      {
         var panelname=document.getElementById('panelname').value;
         console.log(panelname);
       var x=await fetch(process.env.REACT_APP_URL+'AdminListOfArea?panelName='+panelname);
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setmydata(a);
        console.log("mydata",mydata);
      }
     
      useEffect(()=>{loaddata();},[]);

function display(){
         loaddata();
   var data=document.getElementById("tabledata");
	data.style.display='block'

  }

   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Applicant List without Area/City</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">List Of Area</h4>
                                <hr width="100%"/>
                                <div className="row">
                               
                                
                                    <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                    <label>Panel Name</label>
                                                    <select class="form-control" id="panelname">
                                                    <option >Select</option>
															    <option value='Branch'>Branch</option>
                                                                <option value='Depot'>Depot</option>
                                                                <option value='Store'>Store</option>
                                                                <option value='ds'>Direct Seller</option>
																 

																</select>
                                                </div>
                                                
                                                
                                                
                                            </form>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                               
<div class="row">
                                        	<div class="form-group">
															<div>
																

                                                                    <button type="submit" class="btn btn-primary waves-effect waves-light" style={{marginLeft:10}} onClick={display}  >
																	Submit</button>
															</div>
														</div> 
                                                        
                                                       
                                                        	
                                                          
													
												</div>
                               


                                <div class="row" style={{display:'none'}} id='tabledata'>
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Search Item......" aria-label="Search"  />
															</div>
															</div>
														</div>
										{/* <h4 class="m-b-30 m-t-0">Pannel Comission</h4> */}
										<table id="datatable-buttons"
											class="table table-striped table-bordered">
											<thead>
												<tr>
												    <th>Sr No.</th>
												    <th>Applicant Id</th>
													<th>Temp ID</th>
													<th>Applicant Name</th>
													<th>Mobile No.</th>
                                                    
                                                
													
													
													
												</tr>
											</thead>
											<tbody>
												{mydata.length>0 &&
											  mydata.map((row,index)=>{
                                              
                                              return(	
											
                                              <tr>
													
													<td>{index+1}</td>
                                                    <td>{row.ApplicantId}</td>
                                                    <td>{row.TempId}</td>
                                                    <td>{row.ApplicantName}</td>
                                                    <td>{row.MobileNo}</td>
                                                    
                                                   
                                                   
													
									
												</tr>
											
                                             ) 
                                              })
												
                                                }
											</tbody>
										
										</table>
									</div>
								</div>
							</div>
						</div>
                                

                                
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default Adminlistofarea;