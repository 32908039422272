import { useState,useEffect ,React} from "react";
import axios from 'axios';

function AdminBusinessBuildingClub()
{
    const [value,setvalue]=useState(0);
    const [mydata,setdata]=useState([]);
    let z;
    async   function loaddata()
    {
     var x= await  fetch(process.env.REACT_APP_URL+"pd?id=2082");
        var y=await x.json();
          z=await y.recordset;
          console.log('data10',z );
          setdata(z);
         console.log('data2',mydata );
        //return z;

    // axios.post(process.env.REACT_APP_URL+"pd?id=20")
    //      .then((mydata)=>{console.log("data1"+mydata.data)})
    //      .catch(error => console.log(error));

        
    }
    
    useEffect(()=>{
      
        loaddata();
       
           
    },[])
   // setvalue(1);
    return(<div className="content-page">
    <div className="content">
        <div className="">
            <div className="page-header-title">
                <h4 className="page-title">Business Building Club Detail</h4>
                
            </div>
        </div>
        
        <div className="page-content-wrapper ">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-primary">
                            <div className="panel-body">
                                <h4 className="m-t-0 m-b-30">Business Building Club Detail</h4>
                                {/* <hr width="100%"/> */}
                                <div className="row">
                                 <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label>DS ID</label>
                                                     <input type="text" class="form-control"/>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label>Month</label>
                                                    <select class="form-control">
															    <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                     <div className="col-sm-4 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                 <div className="form-group">
                                                    <label>Year</label>
                                                    <select class="form-control">
															     <option>2016</option>
																<option>2017</option>
																<option>2018</option>
																<option>2019</option>
																<option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>

																</select>
                                                </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                 <div className="form-group">
                                                    <div>
                                                        
                                                        <button type="submit"
                                                            className="btn btn-primary waves-effect waves-light" style={{marginLeft:833}}>
                                                            Submit</button>    
                                                                
                                                        
                                                    </div>
                                                </div> 
                                    
                                     
                                    
                                </div>

                               



                                <div class="row">
							<div class="col-md-12">
								<div class="panel panel-primary">
									<div class="panel-body">
                                    <div class="form-group">
															{/* <label>IP OR Login ID</label> */}
															<div>
																<div>
																 <input type="text" class="form-control" placeholder="Your's Total Matching Point:0" style={{textAlign:'center',fontWeight:'900',fontSize:'x-large'}}  />
															</div>
															</div>
														</div>
										
									</div>
								</div>
							</div>
						</div>
                                
                                <div className="row">
                                 <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                                <div className="form-group">
                                                        <label style={{marginLeft:175,fontWeight:'900',fontSize:'large',color:'red'}}>Total Left =</label>
                                                     <table id="datatable-buttons" class="table table-striped table-bordered ">
                                                        <thead>
												<tr>
                                                    <th>Sr No.</th>
												    <th>DS Id</th>
												    <th>Applicant Name</th>
													<th>Kit</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
                                                     </table>
                                                    </div>
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                
                                     <div className="col-sm-6 col-xs-12">
                                    
                                        <div className="m-t-20">
                                            <form className="" action="#">
                                            
                                               <div className="form-group">
                                                    <label style={{marginLeft:150,fontWeight:'900',fontSize:'large',color:'red'}}>Total Right =</label>
                                                     
                                                     <table id="datatable-buttons" class="table table-striped table-bordered">
                                                        <thead>
												<tr>
                                                    <th>Sr No.</th>
												    <th>DS Id</th>
												    <th>Applicant Name</th>
													<th>Kit</th>
													
                                                    
                                                
													
													
													
												</tr>
											</thead>
                                                     </table>
                                                     </div>
                                               
                                               
                                                
                                                
                                            </form>
                                        </div>
                                        
                                        
                                    </div>
                                    
                               
                                    
                                     
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer className="footer"> © 2016 WebAdmin - All Rights
        Reserved. </footer>
</div>)
}

export default AdminBusinessBuildingClub;