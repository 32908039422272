import { useState, useEffect, React } from "react";
import axios from 'axios';


function DSpersonaldetails() {
    const [value, setvalue] = useState(0);
    const [mydata, setmydata] = useState([]);
    let z;
    async function loaddata() {
        var search = document.getElementById('search').value
        var x = await fetch(process.env.REACT_APP_URL+"DSpersonaldetails?TempID=" + localStorage.getItem("tempID"));
        var y = await x.json();
        z = await y.recordset;
        // console.log('data10', z);
        setmydata(z);
        // console.log('data2', mydata);
        //return z;

        // axios.post(process.env.REACT_APP_URL+"pd?id=20")
        //      .then((mydata)=>{console.log("data1"+mydata.data)})
        //      .catch(error => console.log(error));


    }

    useEffect(() => {

        loaddata();


    }, [])
    // setvalue(1);
    
    var [data,setdata]=useState([]);
    
    
    
        async function loaddata()
      {
        var search = document.getElementById('search').value;
        var x = await fetch(process.env.REACT_APP_URL+"DSpersonaldetails?TempID="+search);
       
       var y=await x.json();
       var z=JSON.stringify(y.recordset);
       var a=JSON.parse(z);
       setdata(a);
        console.log("data",data);
      }
     
    return (<div className="content-page">
        <div className="content">
            <div className="">
                <div className="page-header-title">
                    <h4 className="page-title">Update Profile  </h4>

                </div>
            </div>

            <div className="page-content-wrapper ">
                <div className="container">
                   
                 <><div className="row">
                                    <div className="col-sm-12">
                                        <div className="panel panel-primary">
                                            <div className="panel-body">
                                                <h4 className="m-t-0 m-b-30">Search Direct Seller</h4>
                                                <hr width="100%" />
                                                <div className="row">


                                                    <div className="col-sm-6 col-xs-12">

                                                        <div className="m-t-20">
                                                            <form className="" action="#">

                                                                <div className="form-group">
                                                                    <label>Direct Seller ID</label>
                                                                    <input type="text" placeholder="Enter Ds Id" class="form-control" id="search" />




                                                                </div>



                                                            </form>
                                                        </div>
                                                    </div>


                                                </div>



                                            </div>

                                        </div>
                                    </div>
                                </div><div className="form-group">
                                        <div>

                                            <button type="submit" onClick={loaddata} 
                                                className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 860 }}>
                                                Search</button>

                                        </div>
                                    </div>
                                     {data.length > 0 &&
                                    data.map((row, index) => {
                            return (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="panel panel-primary">
                                                <div className="panel-body">

                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Joining Details</h4>
                                                    <hr width="100%" />
                                                     
                        
                                                    <div className="row">

                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Awareness No</label>
                                                                        <label className="form-control">{row.AwarenessID
                                                                        }</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Sponsor No</label>
                                                                        <label className="form-control">{row.SponsorID}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Position</label>
                                                                        <label className="form-control">{row.Position}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Status</label>
                                                                        <label className="form-control">{row.status}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Awareness Name</label>
                                                                        <label className="form-control">Put Awareness Name here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Sponsor Name</label>
                                                                        <label className="form-control">Put Sponsor Name here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Joining Date</label>
                                                                        <label className="form-control">{row.date}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Card</label>
                                                                        <label className="form-control">Put ID Card here</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 


                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Personal Details</h4>
                                                    <hr width="100%" />
                                                   
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Applicant Name</label>
                                                                        <label className="form-control">{row.ApplicantName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Applicant DOB</label>
                                                                        <label className="form-control">{row.ApplicantDob}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Nominee DOB</label>
                                                                        <label className="form-control">{row.NomineeDob}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Guardian Name</label>
                                                                        <label className="form-control">{row.ApplicantGuardionName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Nominee Name</label>
                                                                        <label className="form-control">{row.NomineeName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Gender</label>
                                                                        <label className="form-control">{row.applicantgender}</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Communication Details</h4>
                                                    <hr width="100%" />
                                                  
                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Country</label>
                                                                        <label className="form-control">{row.CountryName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>City</label>
                                                                        <label className="form-control">{row.Cityname}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>House No</label>
                                                                        <label className="form-control">{row.HouseNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Mobile No</label>
                                                                        <label className="form-control">{row.MobileNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Address Proof Type</label>
                                                                        <label className="form-control">{row.AddPType}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Address Proof</label>
                                                                        <input type="file" className="form-control" />
                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>State</label>
                                                                        <label className="form-control">{row.StateName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Area</label>
                                                                        <label className="form-control">{row.areaname}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Email</label>
                                                                        <label className="form-control">{row.Email}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Phone No. Phone no with STD code.</label>
                                                                        <label className="form-control">Put Phone no with STD code. here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Proof Type</label>
                                                                        <label className="form-control">{row.IDPType}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>ID Proof</label>
                                                                        <input type="file" className="form-control" />
                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                             
                                                    <h4 className="m-t-0 m-b-30 form-control btn btn-primary waves-effect waves-light font-monospace" style={{ fontWeight: 700, fontSize: 'large' }}>Bank Details</h4>

                                                    <hr width="100%" />

                                                    <div className="row">
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Bank Name</label>
                                                                        <label className="form-control">{row.BankName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Branch Name</label>
                                                                        <label className="form-control">{row.BranchName}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Account No</label>
                                                                        <label className="form-control">{row.AccNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Pan No</label>
                                                                        <label className="form-control">{row.PanNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Cheque Label Image</label>
                                                                        <input type="file" className="form-control" />
                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>DS Image</label>

                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Bank Brnach State</label>
                                                                        <label className="form-control">Put Awareness Name here</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>IFSC Code</label>
                                                                        <label className="form-control">{row.IFSCNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label style={{ color: 'white' }}>branch</label>
                                                                        <button className="form-control btn btn-primary waves-effect waves-light">Bank Branch details Button</button>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Cheque No.</label>
                                                                        <label className="form-control">{row.ChequeNo}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>Pan Card Image</label>
                                                                        <label className="form-control">{row.PanPhoto}</label>

                                                                        <input type="file" className="form-control"  />
                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>DS Signature</label>
                                                                        <label className="form-control">{row.signature}</label>

                                                                        <button type="submit"
                                                                            className="btn btn-primary waves-effect waves-light form-control">
                                                                            View</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 


                                                </div>
                                                {/* <label>Submit Button</label> */}
                                            </div>
                                        </div>
                                         <div className="form-group">


                                        <div>

                                            <button type="submit"
                                                className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 860 }}>
                                                Submit</button>

                                        </div>




                                    </div>
                                    </div>
  )

                        })
                    }
                                   
                                     
                                    
                                    
                                    
                                    
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="panel panel-primary">
                                                <div className="panel-body">
                                                    <h4 className="m-t-0 m-b-30">Complete KYC</h4>
                                                    <hr width="100%" />

                                                    <div className="row">

                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>YES</label>
                                                                        <input type="Checkbox" style={{ marginLeft: 5 }} />
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xs-12">

                                                            <div className="m-t-20">
                                                                <form className="" action="#">
                                                                    <div className="form-group">
                                                                        <label>Approved Authority:</label>
                                                                        <input type="text" className="form-control" />
                                                                    </div>

                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div><div className="form-group">
                                        <div>

                                            <button type="submit"
                                                className="btn btn-primary waves-effect waves-light" style={{ marginLeft: 860 }}>
                                                Approve</button>

                                        </div>
                                    </div></>
                                    

                </div>
            </div>
        </div>
        <footer className="footer"> © 2016 WebAdmin - All Rights
            Reserved. </footer>
    </div>)
}

export default DSpersonaldetails;